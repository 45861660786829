import config from "../config"
import PlayInfoStrategy from "../config/PlayInfoStrategy"
import { useUserStore } from "/@/kernel-mn/stores/user"
import { useGameStore } from "/@/kernel-mn/stores/game"
import helperHK from "/@/kernel-mn/helpers/helperHK"
import { accMul } from "/@/kernel-mn/utils"

import $ from 'jquery'
import isEmpty from 'lodash/isEmpty'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import { useHot } from "/@/kernel-mn/stores/useHot";

// 获得指定类型的所有彩种
export const getCatPlays = (plays, cat, pan = null) => {
  let arr = []
  for (let k in plays) {
    let play = plays[k]
    if (play.cat && play.cat === cat) {
      if (pan) {
        if (play.pan === pan) {
          arr.push(play)
        }
      } else {
        arr.push(play)
      }
    }
  }
  return arr
}

export const getTicketCodeStFromCmd = (ticket, playid) => {
  let codeStr = ''
  let play = config.getPlayInfo(ticket.play_id)
  let ballinfo = config.getBallCmdInfo(ticket.play_id)
  let ballArr = []
  if (play.note_tag === 'lhcMoney') {
    let ballsArr = (ticket.play_cmd.split('#')[1]).split('**')
    for (let i in ballsArr) {
      let arr = ballsArr[i].split('*')
      let area = ballinfo.areas[0]
      let ball = area[arr[0]]
      ballArr.push(ball.name + ':' + arr[2] + '元')
    }
    codeStr += ballArr.join('|')
  }
  //  单式
  else if (play.simplex) {
    codeStr = ticket.play_cmd
    let codeArr = null
    if (codeStr.length > 0) {
      codeArr = codeStr.split('#')
      codeStr = (codeArr && codeArr.length > 1) ? codeArr[1] : codeStr
    }
    if (codeStr.length > 0) {
      codeArr = codeStr.split('*')
      codeStr = (codeArr && codeArr.length > 0) ? codeArr[0] : codeStr
    }

    let ballIds = codeStr.split(',')
    if (ballinfo.areas[0].isoption) {
      let checkArea = ballinfo.areas[0]
      if (checkArea.isoption && ballIds.length > 0) {
        codeStr = ' '
        let checkBalls = ballIds[0].split('')
        for (let chIdx in checkBalls) {
          let ballId = Number(checkArea.baseballid) + Number((checkBalls[chIdx]))
          codeStr += ballinfo.balls[ballId].name
        }
        let selectNums = []
        for (let ballIdx in ballIds) {
          if (+ballIdx > 0) {
            selectNums.push(ballIds[ballIdx])
          }
        }
        codeStr += selectNums.join(',')
      }
    }
  } else if (ticket.play_cmd.length > 0) {
    //  对碰的号码cmd不是配置的
    if (play.cmd_pair_ball) {
      let ballstr = (ticket.play_cmd.split('#')[1]).split('*')[0]
      for (let i = 0; i < ballstr.length; i += ballinfo.areas[0].digit) {
        let strnum = ballstr.substring(i, i + ballinfo.areas[0].digit)
        ballArr.push(strnum)
      }
    }
    //  普通拆解
    else {
      let ballstr = (ticket.play_cmd.split('#')[1]).split('*')[0]
      let areas = ballstr.split(',')
      let isMulArea = areas.length > 1
      for (let i in areas) {
        let headstr = isMulArea ? ballinfo.areas[i].show : ''
        let codes = []
        let digit = ballinfo.areas[i].digit
        for (let j = 0; j < areas[i].length; j += digit) {
          let strnum = areas[i].substring(j, j + digit)
          if (play.play_cname === '正码1-6' && Number(digit) === 3) {
            let index = Number(strnum.substr(0, 1)) - 1
            const gameStr = { 0: '正码一', 1: '正码二', 2: '正码三', 3: '正码四', 4: '正码五', 5: '正码六' }[index]
            codes.push(gameStr + ballinfo.areas[index][strnum].name)
          } else if ((play.play_cname === '一肖' && Number(strnum) >= 12) || (play.play_cname === '特码' && Number(strnum) >= 50)) {
            codes.push(ballinfo.areas[1][strnum].name)
          } else {
            codes.push(ballinfo.areas[i][strnum].name)
          }
        }
        if (codes.length > 0) {
          if (headstr.length > 0) {
            ballArr.push(headstr + ':' + codes.join(','))
          } else {
            ballArr.push(codes.join(','))
          }
        }
      }
    }

    codeStr += ballArr.join('|')
  }
  console.error('====>>>codeStr:' + codeStr)
  return codeStr
}

// 获得指定类型的所有彩种的id
export const getCatGameIds = (plays, cats, pan = null) => {
  let arr = []
  for (let i = 0; i < cats.length; i++) {
    let cat = cats[i]
    arr = arr.concat(getCatPlays(plays, cat, pan))
  }
  let retArr = []
  for (let i = 0; i < arr.length; i++) {
    retArr.push(arr[i].id)
  }
  return retArr
}

// 是否为信用玩法
export const playIsXY = (play, curPlayid) => {
  if (play && play.cat === 'YDC') {
    return true
  }
  if (!play || !curPlayid) {
    return false
  }
  const playConfig = {
    'SSC': {
      min: 103,
      max: 110
    },
    'XYFT': {
      min: 803,
      max: 810
    },
    'K3': {
      min: 701,
      max: 705
    }
  }
  if (playConfig[play.cat]) {
    const last = Number(curPlayid.toString().substring(curPlayid.toString().length - 3, curPlayid.toString().length))
    if (last >= playConfig[play.cat].min && last <= playConfig[play.cat].max) {
      return true
    }
  }
  return false
}

// 是否是经典彩
export const isKgPage = (playid, curPlayid) => {
  let play = config.getPlayInfo(playid)
  if (play && (play.cat === 'PK10JDC' || play.cat === 'SSCJDC' || play.cat === 'XYFTJDC')) {
    return true
  }
  return playIsXY(play, curPlayid)
}

export const isK3Page = (playid) => {
  let play = config.getPlayInfo(playid)
  return play && play.cat === 'K3'
}

export const getPlayK3HeZhiBallOdds = (mainPlayId, subPlayId, resId, ballCmd, isTure = false, len = 0) => {
  let value = getCurrentPlayRebate(mainPlayId)
  let mainPlay = config.getPlayInfo(mainPlayId)
  let subPlayid = subPlayId
  const FC3D = PlayInfoStrategy.FC3D.plays
  // FC3D
  for (let i in FC3D) {
    if (Number(String(subPlayId).split('753')[1]) === Number(i)) {
      subPlayid = Number(FC3D[i].rule_id)
    }
  }
  const gameStore = useGameStore()
  let playDetail = gameStore.playDetails[subPlayid]
  let pricePlans = gameStore.playPricingPlan
  if (playDetail && pricePlans) {
    let prices = pricePlans[mainPlayId + '-' + resId]
    if (prices) {
      let rebate = getBaseRebate(mainPlayId)
      //  子玩法拥有培率
      if (Number(mainPlayId) === 753) { // 福彩3d
        let baseNoteNum = playDetail.base_note_num
        let price = prices[subPlayid][0]
        if (subPlayId === 753009 || subPlayId === 753010) {
          price = formatFCZXPrice(len, subPlayId, prices)
        } else {
          price = prices[subPlayid][Number(ballCmd)] || prices[subPlayid][0]
        }
        let [odd, s_odd] = (price + '').split('-');
        let newodd = Math.round((+odd * 0.001 + (rebate - value) * 0.0001) * baseNoteNum * (playDetail.sub_play_plan_num * (1 / playDetail.sub_play_plan_num_win)) * 1000) / 1000;
        return newodd - (+s_odd || 0);
      } else {
        if (playDetail.subPlay && playDetail.subPlay.length > 0) {
          //  基础购票注数
          let id = String(subPlayid) + String(ballCmd)
          for (let i = 0; i < playDetail.subPlay.length; i++) {
            let item = playDetail.subPlay[i]
            if (Number(item.play_id) === Number(id)) {
              playDetail = item
              break
            }
          }
          let baseNoteNum = playDetail.base_note_num
          let price = prices[subPlayid + '' + ballCmd]
          if (price == null) {
            price = 0
          } else {
            price = price['0']
          }
          let [odd, s_odd] = (price + '').split('-');
          let newodd = Math.round((+odd * 0.001 + (rebate - value) * 0.0001) * baseNoteNum * (playDetail.sub_play_plan_num * (1 / playDetail.sub_play_plan_num_win)) * 1000) / 1000;
          return newodd - (+s_odd || 0);
        }
        // C盘
        else if (mainPlay.pan === 'C' || isTure) {
          let price = getBallPrices2(mainPlayId, subPlayid, resId, Number(ballCmd), true)
          let odd = computeOdds('C', {
            price: price,
            odds_ratio: playDetail.odds_ratio,
            rebate: rebate,
            value: value,
            base_note_num: playDetail.base_note_num
          })
          return +Number(odd).toFixed(3)
        }
      }
    } else {
      return 0
    }
  }
  return 0
}

export const formatFCZXPrice = (len, subPlayId, prices) => {
  const ZX3 = [5, 6, 7, 8, 9, 10]
  const ZX6 = [4, 5, 6, 7, 8]
  const isZX3 = subPlayId === 753009
  let price = 0
  if (isZX3) {
    price = prices[subPlayId][ZX3.indexOf(len)]
  } else {
    price = prices[subPlayId][ZX6.indexOf(len)]
  }
  return price
}

//  数字彩res_id和ballId为0
export const getPlayK3SubplayOdds = (mainPlayId, subPlayid, resId, ballId) => {
  return getPlayOdds(mainPlayId, subPlayid, resId, ballId, getCurrentPlayRebate(mainPlayId))
}

//  数字彩res_id和ballId为0
export const getPlayOdds = (mainPlayId, subPlayid, resId, ballId, value, getCurOdds = false) => {
  const gameStore = useGameStore()
  let playDetail = gameStore.playDetails[subPlayid]
  let pricePlans = gameStore.playPricingPlan
  let mainPlay = config.getPlayInfo(mainPlayId)
  let subPlayCfg = config.getPlayInfo(subPlayid)
  if (mainPlay && subPlayCfg && playDetail && pricePlans) {
    let rebate = getBaseRebate(mainPlayId)
    let condition = playDetail.subPlay && playDetail.subPlay.length > 0 && !getCurOdds
    let conditionEx = mainPlay.cat === 'MARKSIX' || mainPlay.cat === 'LHCJDC' ? condition && !subPlayCfg.ball_odds : condition
    //  子玩法拥有培率
    if (conditionEx) {
      let subPlays = playDetail.subPlay
      let subOdds = []
      if (mainPlay) {
        subPlays.map((item) => {
          if (mainPlay.cat === 'MARKSIX' || mainPlay.cat === 'LHCJDC') {
            let price = getBallPrices2(mainPlayId, subPlayid, resId, item.play_id, true)
            let odd = computeOdds('C', {
              price: price,
              odds_ratio: playDetail.odds_ratio,
              rebate: rebate,
              value: value,
              base_note_num: playDetail.base_note_num
            })
            subOdds.push(odd)
          } else {
            let price = getBallPrices2(mainPlayId, item.play_id, resId, ballId, true)
            let odd = computeOdds('A', {
              price: price,
              rebate: rebate,
              value: value,
              base_note_num: item.base_note_num,
              sub_play_plan_num: item.sub_play_plan_num,
              sub_play_plan_num_win: item.sub_play_plan_num_win
            })
            subOdds.push(odd)
          }
        })
      }
      return subOdds
    } else {
      let price = getBallPrices2(mainPlayId, subPlayid, resId, ballId, true);
      let odd = 0
      // C盘算法
      if (mainPlay && (mainPlay.pan === 'C' || mainPlay.cat === 'MARKSIX')) {
        odd = computeOdds('C', {
          price: price,
          odds_ratio: playDetail.odds_ratio,
          rebate: rebate,
          value: value,
          base_note_num: playDetail.base_note_num
        })
      } else {
        odd = computeOdds('A', {
          price: price,
          rebate: rebate,
          value: value,
          base_note_num: playDetail.base_note_num,
          sub_play_plan_num: playDetail.sub_play_plan_num,
          sub_play_plan_num_win: playDetail.sub_play_plan_num_win
        })
      }
      return odd
    }
  }
  return 0
}

// 计算赔率
export const computeOdds = (type, params = {} as any) => {
  let [odd, s_odd] = (params.price + '').split('-');
  let newodd = 0
  switch (type) {
    case 'A': { // A盘公式
      newodd = Math.round((+odd / 1000 + (params.rebate - params.value) / 10000) * params.base_note_num * (params.sub_play_plan_num * (1 / params.sub_play_plan_num_win)) * 1000) / 1000;
      return newodd - (+s_odd || 0)
    }
    case 'C': { // 信用盘公式
      newodd = Math.round((+odd / 1000) * ((params.odds_ratio / 1000) + (params.rebate - params.value) / 10000) * params.base_note_num * 1000) / 1000;
      return newodd - (+s_odd || 0)
    }
    default:
      return 0
  }
}

export const getCurrentPlayRebate = (mainPlayId) => {
  let rebate = getBaseRebate(mainPlayId)
  // const gameStore = useGameStore()
  // let slider = gameStore.selectSliders[mainPlayId] || 0
  const slider = 0
  return (rebate - slider)
}

//  获取玩家彩种基础返点
export const getBaseRebate = (mainPlayId) => {
  let mainPlay = config.getPlayInfo(mainPlayId)
  const userStore = useUserStore()
  let baseRebates = userStore.uBaseRebates || {}
  let keys = {
    'PK10': 'rebate_sc',
    'PK10JDC': 'rebate_sc',
    'XYFTJDC': 'rebate_sc',
    'XYFT': 'rebate_sc',
    'SSC': 'rebate',
    'SSCJDC': 'rebate',
    '115': 'rebate_115',
    'K3': 'rebate_k3',
    'MARKSIX': 'rebate_ms',
    'LHCJDC': 'rebate_ms',
    'FC3D': 'rebate'
  }
  let rebate = mainPlay ? baseRebates[keys[mainPlay.cat]] : 0
  if (rebate == null) {
    rebate = 0
  }
  return rebate
}
// isorg:真返回原来的
export const getBallPrices2 = (mainPlayId, playid, resId, ballId, isorg = false) => {
  const gameStore = useGameStore()
  let pricePlans = gameStore.playPricingPlan
  if (pricePlans) {
    let prices = pricePlans[mainPlayId + '-' + resId]
    if (prices) {
      let ballPrices = prices[playid]
      if (ballPrices) {
        let ball_prices = ballPrices[ballId];
        if (ball_prices) {
          let [odd, s_odd] = (ball_prices + '').split('-');
          if (isorg) return ball_prices;
          return +odd - (+s_odd || 0)
        }
      }
    }
  }
  return 0
}

//  是否已封期
export const isFrost = (term) => {
  if (term === null) {
    return true
  }
  let currentTime = new Date().getTime()
  return currentTime >= term.stopTime || currentTime < term.startTime
}

export const startTime = (term) => {
  let delayTime = Number(term.begin_time) - Number(term.start_sale_offset) * 1000 - Number(term.db_sys_date) //  服务器时间多久后结束
  return new Date().getTime() + delayTime
}

export const stopTime = (term) => {
  let delayTime = Number(term.end_time) - Number(term.stop_sale_offset) * 1000 - Number(term.db_sys_date) //  服务器时间多久后结束
  return new Date().getTime() + delayTime
}

export const parseWincode = (mainPlayId, historyItem) => {
  let play = config.getPlayInfo(mainPlayId)
  let wincodeArr = []
  let numlen = 2
  if (['SSC', 'SSCJDC', 'LHC', 'K3'].includes(play.cat)) {
    numlen = 1
  }
  if (historyItem && historyItem.win_code) {
    if (play.cat === 'YDC') {
      return [historyItem.win_code.substring(historyItem.win_code.length - 1, historyItem.win_code.length)]
    }
    for (let i = 0; i < historyItem.win_code.length; i += numlen) {
      let strnum = historyItem.win_code.substring(i, i + numlen)
      wincodeArr.push(strnum)
    }
  }
  if (play.cat === 'K3') {
    wincodeArr = wincodeArr.sort((a, b) => Number(a) - Number(b))
  }
  return wincodeArr
}

//  获得赔率
export const getBallPrices = (playid, resId, ballId) => {
  let mainPlayId = getMainPlayId(playid)
  return getBallPrices2(mainPlayId, playid, resId, ballId)
}

export const getMainPlayId = (subPlayId) => {
  let strSubPlayId = subPlayId + ''
  if (strSubPlayId.length > 3) {
    return Number((subPlayId + '').substring(0, strSubPlayId.length - 3))
  }
}

export const singleTextFormat = (text, mainPlayId, subPlayId) => {
  if (!text || text.length === 0) {
    return ''
  }

  let play = config.getPlayInfo(subPlayId)
  if (play === null || !play.simplex) {
    return text
  }

  let numStart = play.numStart //  起始号码
  let numEnd = play.numEnd //  结束号码
  let numCount = play.numCount //  组成一注的号码个数
  let repeat = play.repeat //  号码是否可重复
  let numWidth = play.numWidth //  单个号码的宽度

  let text2 = text.replace(/[|，|\\r|\\n|\s]+/g, ',')
  //  删除所有非数字
  text2 = text2.replace(/[^(\d|,)]/g, '')
  let arrTmp = text2.split(',')

  if (numStart === null || numEnd === null || numCount === null || repeat === null) {
    return text
  }
  if (numStart >= numEnd || numCount < 1 || numEnd < 1) {
    return text
  }
  let arr2 = []
  /* eslint-disable */
  let numLen = Number(numWidth ? numWidth : ('' + numEnd).length)
  /* eslint-enable */
  numLen *= numCount
  for (let i in arrTmp) {
    let str = arrTmp[i]
    for (let j = 0; j < str.length; j += numLen) {
      let ballStr = str.substring(j, j + numLen)
      arr2.push(ballStr)
    }
  }
  return arr2.join(',')
}

export const convertSingleTextToSelectIds = (text, mainPlayId, subPlayId) => {
  if (!text || text.length === 0) {
    return []
  }

  let play = config.getPlayInfo(subPlayId)
  if (play === null || !play.simplex) {
    return []
  }

  let numStart = play.numStart //  起始号码
  let numEnd = play.numEnd //  结束号码
  let numCount = play.numCount //  组成一注的号码个数
  let repeat = play.repeat //  号码是否可重复
  let numWidth = play.numWidth //  单个号码的宽度

  if (numStart === null || numEnd === null || numCount === null || repeat === null) {
    return []
  }
  if (numStart >= numEnd || numCount < 1 || numEnd < 1) {
    return []
  }
  let retArr = []
  let nosameArr = []
  /* eslint-disable */
  let numLen = numWidth ? numWidth : ('' + numEnd).length
  /* eslint-enable */
  let text2 = text.replace(/[|，|\\r|\\n|\s]+/g, ',')
  //  删除所有非数字
  text2 = text2.replace(/[^(\d|,)]/g, '')
  let arrTmp = text2.split(',')

  for (let i in arrTmp) {
    let noteStr = arrTmp[i]
    if (noteStr.length === numLen * numCount) {
      let isValid = true
      let testRepeatStr = ''
      let repeatContent = {}
      for (let j = 0; j < noteStr.length; j += numLen) {
        let ballStr = noteStr.substring(j, j + numLen)
        let ballNum = parseInt(ballStr)
        if (ballNum < numStart || ballNum > numEnd) {
          isValid = false
          break
        } else {
          if (testRepeatStr.indexOf(ballStr) !== -1) {
            if (repeatContent[ballStr]) {
              repeatContent[ballStr] = repeatContent[ballStr] + 1
            } else {
              repeatContent[ballStr] = 2
            }
            if (repeatContent[ballStr] > repeat) {
              isValid = false
              break
            }
          }
          testRepeatStr += ballStr
        }
      }
      //  特殊处理组三单式
      if (play.r3z3ds) {
        isValid = false
        for (let obj in repeatContent) {
          if (repeatContent[obj] === 2) {
            isValid = true
            break
          }
        }
      }
      if (isValid) {
        //   部分玩法需要去重
        if (play.filterSingle) {
          let tempArr = noteStr.split('')
          tempArr.sort()
          let newStr = tempArr.join('')
          if (nosameArr.indexOf(newStr) === -1) {
            nosameArr.push(newStr)
            retArr.push(noteStr)
          }
        }
        //  去掉直选单式完全一样的号码
        else if (play.filterOnly) {
          if (retArr.indexOf(noteStr) === -1) {
            retArr.push(noteStr)
          }
        } else {
          retArr.push(noteStr)
        }
      }
    }
  }
  return retArr
}

export const ballsWithArea = (playId, selectedIds) => {
  let dictIds = {}
  for (let i in selectedIds) {
    dictIds[selectedIds[i]] = true
  }
  let allAreaIds = []
  let ballInfo = config.getBallInfo(playId)
  if (ballInfo) {
    for (let i in ballInfo.areas) {
      let area = ballInfo.areas[i]
      let areaids = []
      for (let j in area.ids) {
        if (dictIds[area.ids[j]]) {
          areaids.push(ballInfo.balls[area.ids[j]])
        }
      }
      allAreaIds.push(areaids)
    }
  }
  return allAreaIds
}
//  数字彩机选
export const random = (playId) => {
  let selectedIds = []
  let ballInfo = config.getBallInfo(playId)
  let play = config.getPlayInfo(playId)
  if (ballInfo) {
    if (play.note_tag === 'qianN' || play.note_tag === 'qianDX') {
      let cmds = []
      for (let i in ballInfo.areas) {
        let area = ballInfo.areas[i]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'sscComb12') {
      let cmds = []
      let area = ballInfo.areas[0]
      let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      while (cmds.indexOf(ball.cmd) !== -1) {
        ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      }
      cmds.push(ball.cmd)
      selectedIds.push(ball.id)

      for (let i = 0; i < 2; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'sscComb13') {
      let cmds = []
      let area = ballInfo.areas[0]
      let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      while (cmds.indexOf(ball.cmd) !== -1) {
        ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      }
      cmds.push(ball.cmd)
      selectedIds.push(ball.id)

      for (let i = 0; i < 3; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'sscComb21' || play.note_tag === 'r2DirectSum' || play.note_tag === 'r2ssc2Sum') {
      let cmds = []
      for (let i = 0; i < 2; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }

      let area = ballInfo.areas[1]
      let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      while (cmds.indexOf(ball.cmd) !== -1) {
        ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      }
      cmds.push(ball.cmd)
      selectedIds.push(ball.id)
    } else if (play.note_tag === 'r3DirectSum') {
      let cmds = []
      for (let i = 0; i < 3; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }

      let area = ballInfo.areas[1]
      let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      while (cmds.indexOf(ball.cmd) !== -1) {
        ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      }
      cmds.push(ball.cmd)
      selectedIds.push(ball.id)
    } else if (play.note_tag === 'count' || play.note_tag === 'ssc3CombSpecial') {
      let area = ballInfo.areas[Math.floor((Math.random() * ballInfo.areas.length))]
      selectedIds.push(area.ids[Math.floor((Math.random() * area.ids.length))])
    } else if (play.note_tag === 'sscDirect' || play.note_tag === 'ssc3DicectComb') {
      let cmds = []
      for (let i in ballInfo.areas) {
        let area = ballInfo.areas[i]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'sscComb2' || play.note_tag === 'ssc3Comb3') {
      let cmds = []
      for (let i = 0; i < 2; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'sscComb3') {
      let cmds = []
      for (let i = 0; i < 3; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'sscComb4') {
      let cmds = []
      for (let i = 0; i < 4; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'sscComb5') {
      let cmds = []
      for (let i = 0; i < 5; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'sscComb6') {
      let cmds = []
      for (let i = 0; i < 6; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'sscComb7') {
      let cmds = []
      for (let i = 0; i < 7; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'sscComb8') {
      let cmds = []
      for (let i = 0; i < 8; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'dtComb2') {
      let cmds = []
      for (let i in ballInfo.areas) {
        let area = ballInfo.areas[i]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'dtComb3') {
      let cmds = []

      let area = ballInfo.areas[0]
      let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      while (cmds.indexOf(ball.cmd) !== -1) {
        ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      }
      cmds.push(ball.cmd)
      selectedIds.push(ball.id)

      for (let i = 0; i < 2; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'dtComb4') {
      let cmds = []

      let area = ballInfo.areas[0]
      let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      while (cmds.indexOf(ball.cmd) !== -1) {
        ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      }
      cmds.push(ball.cmd)
      selectedIds.push(ball.id)

      for (let i = 0; i < 3; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'dtComb5') {
      let cmds = []

      let area = ballInfo.areas[0]
      let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      while (cmds.indexOf(ball.cmd) !== -1) {
        ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      }
      cmds.push(ball.cmd)
      selectedIds.push(ball.id)

      for (let i = 0; i < 4; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'dtComb6') {
      let cmds = []

      let area = ballInfo.areas[0]
      let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      while (cmds.indexOf(ball.cmd) !== -1) {
        ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      }
      cmds.push(ball.cmd)
      selectedIds.push(ball.id)

      for (let i = 0; i < 5; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'dtComb7') {
      let cmds = []

      let area = ballInfo.areas[0]
      let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      while (cmds.indexOf(ball.cmd) !== -1) {
        ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      }
      cmds.push(ball.cmd)
      selectedIds.push(ball.id)

      for (let i = 0; i < 6; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'dtComb8') {
      let cmds = []

      let area = ballInfo.areas[0]
      let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      while (cmds.indexOf(ball.cmd) !== -1) {
        ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      }
      cmds.push(ball.cmd)
      selectedIds.push(ball.id)

      for (let i = 0; i < 7; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'random2num') {
      let keys = []
      let num = 2
      while (num) {
        let key = Math.floor((Math.random() * ballInfo.areas.length))
        if (keys.indexOf(key) === -1) {
          let area = ballInfo.areas[key]
          selectedIds.push(area.ids[Math.floor((Math.random() * area.ids.length))])
          keys.push(key)
          num = num - 1
        }
      }
    } else if (play.note_tag === 'random3num') {
      let keys = []
      let num = 3
      while (num) {
        let key = Math.floor((Math.random() * ballInfo.areas.length))
        if (keys.indexOf(key) === -1) {
          let area = ballInfo.areas[key]
          selectedIds.push(area.ids[Math.floor((Math.random() * area.ids.length))])
          keys.push(key)
          num = num - 1
        }
      }
    } else if (play.note_tag === 'random4num') {
      let keys = []
      let num = 4
      while (num) {
        let key = Math.floor((Math.random() * ballInfo.areas.length))
        if (keys.indexOf(key) === -1) {
          let area = ballInfo.areas[key]
          selectedIds.push(area.ids[Math.floor((Math.random() * area.ids.length))])
          keys.push(key)
          num = num - 1
        }
      }
    } else if (play.note_tag === 'r2Combnum') {
      let cmds = []
      for (let i = 0; i < 2; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }

      for (let i = 0; i < 2; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'r3Comb3') {
      let cmds = []
      for (let i = 0; i < 3; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }

      for (let i = 0; i < 2; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'r3Comb6') {
      let cmds = []
      for (let i = 0; i < 3; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }

      for (let i = 0; i < 3; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'r4Comb24') {
      let cmds = []
      for (let i = 0; i < 4; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }

      for (let i = 0; i < 4; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'r4Comb2') {
      let cmds = []
      for (let i = 0; i < 4; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }

      for (let i = 0; i < 2; i++) {
        let area = ballInfo.areas[1]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'r4Comb12') {
      let cmds = []
      for (let i = 0; i < 4; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }

      cmds = []
      let area = ballInfo.areas[1]
      let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      while (cmds.indexOf(ball.cmd) !== -1) {
        ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      }
      cmds.push(ball.cmd)
      selectedIds.push(ball.id)

      for (let i = 0; i < 2; i++) {
        let area = ballInfo.areas[2]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    } else if (play.note_tag === 'r4Comb4') {
      let cmds = []
      for (let i = 0; i < 4; i++) {
        let area = ballInfo.areas[0]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }

      cmds = []
      let area = ballInfo.areas[1]
      let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      while (cmds.indexOf(ball.cmd) !== -1) {
        ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
      }
      cmds.push(ball.cmd)
      selectedIds.push(ball.id)

      for (let i = 0; i < 1; i++) {
        let area = ballInfo.areas[2]
        let ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        while (cmds.indexOf(ball.cmd) !== -1) {
          ball = ballInfo.balls[area.ids[Math.floor((Math.random() * area.ids.length))]]
        }
        cmds.push(ball.cmd)
        selectedIds.push(ball.id)
      }
    }
    //  前二直选单式
    else if (play.note_tag === 'zxds2star' || play.note_tag === 'comb2star') {
      let balls = []
      for (let i = 0; i < 2; i++) {
        let ball = Math.floor((Math.random() * 10))
        while (balls.indexOf(ball) !== -1) {
          ball = Math.floor((Math.random() * 10))
        }
        balls.push(ball)
      }
      selectedIds.push(balls.join(''))
    } else if (play.note_tag === 'zxds3star') {
      let balls = []
      for (let i = 0; i < 3; i++) {
        let ball = Math.floor((Math.random() * 10))
        balls.push(ball)
      }
      selectedIds.push(balls.join(''))
    } else if (play.note_tag === 'zxds4star') {
      let balls = []
      for (let i = 0; i < 4; i++) {
        let ball = Math.floor((Math.random() * 10))
        balls.push(ball)
      }
      selectedIds.push(balls.join(''))
    } else if (play.note_tag === 'zxds5star') {
      let balls = []
      for (let i = 0; i < 5; i++) {
        let ball = Math.floor((Math.random() * 10))
        balls.push(ball)
      }
      selectedIds.push(balls.join(''))
    } else if (play.note_tag === 'random2Zxds') {
      let balls = []
      for (let i = 0; i < 2; i++) {
        let ball = Math.floor((Math.random() * 5)) + 1
        while (balls.indexOf(ball) !== -1) {
          ball = Math.floor((Math.random() * 5)) + 1
        }
        balls.push(ball)
      }
      selectedIds.push(balls.join(''))

      balls = []
      for (let i = 0; i < 2; i++) {
        let ball = Math.floor((Math.random() * 10))
        while (balls.indexOf(ball) !== -1) {
          ball = Math.floor((Math.random() * 10))
        }
        balls.push(ball)
      }
      selectedIds.push(balls.join(''))
    } else if (play.note_tag === 'random3Zxds') {
      let balls = []
      for (let i = 0; i < 3; i++) {
        let ball = Math.floor((Math.random() * 5)) + 1
        while (balls.indexOf(ball) !== -1) {
          ball = Math.floor((Math.random() * 5)) + 1
        }
        balls.push(ball)
      }
      selectedIds.push(balls.join(''))

      balls = []
      if (play.r3z3ds) {
        let same = Math.floor((Math.random() * 10))
        balls.push(same)
        balls.push(same)
        let ball = Math.floor((Math.random() * 10))
        while (balls.indexOf(ball) !== -1) {
          ball = Math.floor((Math.random() * 10))
        }
        balls.push(ball)
      } else {
        for (let i = 0; i < 3; i++) {
          let ball = Math.floor((Math.random() * 10))
          while (balls.indexOf(ball) !== -1) {
            ball = Math.floor((Math.random() * 10))
          }
          balls.push(ball)
        }
      }
      selectedIds.push(balls.join(''))
    } else if (play.note_tag === 'random4Zxds') {
      let balls = []
      for (let i = 0; i < 4; i++) {
        let ball = Math.floor((Math.random() * 5)) + 1
        while (balls.indexOf(ball) !== -1) {
          ball = Math.floor((Math.random() * 5)) + 1
        }
        balls.push(ball)
      }
      selectedIds.push(balls.join(''))

      balls = []
      for (let i = 0; i < 4; i++) {
        let ball = Math.floor((Math.random() * 10))
        while (balls.indexOf(ball) !== -1) {
          ball = Math.floor((Math.random() * 10))
        }
        balls.push(ball)
      }
      selectedIds.push(balls.join(''))
    } else {
      let area = ballInfo.areas[Math.floor((Math.random() * ballInfo.areas.length))]
      selectedIds.push(area.ids[Math.floor((Math.random() * area.ids.length))])
    }
  }
  return selectedIds
}

export const createBasket = () => {
  return {
    multx: 1, // 方案倍数
    keys: [], // 每一个Item的key
    items: {}, // {key:item}
    total_note: 0, // 总注数
    total_amount: 0, // 总金额
    bonus: 0, // 奖金
  }
}

export const updateBasket = (basket) => {
  basket.total_note = 0
  basket.total_amount = 0
  basket.bonus = 0; // 奖金
  for (let i = 0; i < basket.keys.length; i++) {
    let key = basket.keys[i]
    let basketItem = basket.items[key]
    basket.total_note += basketItem.note
    basket.total_amount += accMul(Number(accMul(basketItem.unit_price, basketItem.note, 0)), basketItem.multx, 0)
    basket.bonus += accMul(basketItem.amount, basketItem.odds, 0)
  }
  basket.profit = +((basket.bonus - basket.total_amount) || 0).toFixed(3) || 0
  basket.total_amount = accMul(basket.total_amount, basket.multx, 0)
  basket.total_amount = accMul(basket.total_amount, 1000, 0)
}

export const ballCmdsWithArea = (playId, selectedIds) => {
  let dictIds = {}
  for (let i in selectedIds) {
    dictIds[selectedIds[i]] = true
  }
  let allAreaIds = []
  let ballInfo = config.getBallInfo(playId)
  if (ballInfo) {
    for (let i in ballInfo.areas) {
      let area = ballInfo.areas[i]
      let areaids = []
      for (let j in area.ids) {
        if (dictIds[area.ids[j]]) {
          areaids.push(Number(ballInfo.balls[area.ids[j]].cmd))
        }
      }
      allAreaIds.push(areaids)
    }
  }
  return allAreaIds
}

export const mulNoRepeat = (array) => {
  if (!$.isArray(array)) {
    /* eslint-disable */
    throw '输入必须为阵列'
    /* eslint-enable */
  }
  if (isEmpty(array)) return 0
  let b = []
  let betnum = 0
  $.map(array, function (a) {
    $.isArray(a) || (a = [a])
    if (a.length === 0) return !1
    a = flatten(a)
    if (b.length === 0) {
      $.map(a, function (a) {
        b.push([a])
      })
    } else {
      let c = []
      $.map(a, function (a) {
        $.map(b, function (b) {
          b = b.slice()
          b.push(a)
          c.push(b)
        })
      })
      b = c
    }
  })
  $.map(b, function (arr) {
    uniq(arr).length >= array.length && betnum++
  })
  return betnum
}
/* eslint-disable */
export const selectNoRepeat = (array, first, second) => {
  function Cnm(e, t) {
    let i = 0,
      a = 1,
      n = 1
    for (i = 0; i < t; i++) a *= e - i
    for (i = 0; i < t; i++) n *= t - i
    return a / n
  }

  function i(t, i, a, s) {
    if (t.length >= a && i.length >= s) {
      f = 0
      for (n in t) {
        r = t[n]
        if ($.inArray(r, i) !== -1) {
          f++
        }
      }
      h = Cnm(t.length, a) * Cnm(i.length, s)
      if (f > 0) {
        if (first < second) {
          h -= Cnm(f, 1) * Cnm(array[1].length - 1, second - 1)
        } else if (first > second) {
          h -= Cnm(f, 2) * Cnm(2, 1)
          if (array[0].length - f > 0) {
            h -= Cnm(f, 1) * Cnm(array[0].length - f, 1)
          }
        }
      }
    }
    return h
  }

  var n,
    r,
    u = 0,
    h = 0,
    f = 0
  u = i(array[0], array[1], first, second)
  return u
}

/* eslint-enable */

export const mul = (arr, d = 0) => {
  if (arr.length === 0) return 0
  var note = 1
  for (let i in arr) {
    let a = arr[i]
    note *= a.length || (d-- ? 1 : 0)
  }
  return note
}

//  按选号玩的索引位置计算注数
export const noteByIndex = (selectedIndexs, tag) => {
  var noteWithIndex = {
    'ssc2Sum': [1, 1, 2, 2, 3, 3, 4, 4, 5, 4, 4, 3, 3, 2, 2, 1, 1], //  时时彩前二/后二组选和值
    'ssc3Sum': [1, 2, 2, 4, 5, 6, 8, 10, 11, 13, 14, 14, 15, 15, 14, 14, 13, 11, 10, 8, 6, 5, 4, 2, 2, 1], //  时时彩前三/中三/后三组选和值
    'ssc3DirectSum': [1, 3, 6, 10, 15, 21, 28, 36, 45, 55, 63, 69, 73, 75, 75, 73, 69, 63, 55, 45, 36, 28, 21, 15, 10, 6, 3, 1], //  时时彩前三/中三/后三直选和值
    'ssc2DirectSum': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1], //  时时彩前二/后二直选和值
    'ssc2Cross': [10, 18, 16, 14, 12, 10, 8, 6, 4, 2], //  时时彩前二/后二直选跨度
    'ssc3Cross': [10, 54, 96, 126, 144, 150, 144, 126, 96, 54] //  时时彩前三/中三/后三直选跨度
  }
  var note = 0
  for (let i in selectedIndexs) {
    let indexs = selectedIndexs[i]
    for (let j in indexs) {
      note += noteWithIndex[tag][Number(indexs[j])] || 0
    }
  }
  return note
}

//  组合算法
export const combine = (arr, d) => {
  let func = (a) => {
    let count = 1
    for (let b = 2; b <= a; b++) {
      count *= b
    }
    return count
  }
  let len = flatten(arr).length
  return d > len ? 0 : d === len ? 1 : func(len) / (func(d) * func(len - d))
}

export const choose = (arr, size) => {
  var allResult = []
  var newarr = []
  for (let i = 0; i < arr.length; i++) {
    let a = arr[i]
    if (a.length > 0) {
      newarr.push(a)
    }
  }

  // 从数组中取出N个元素
  // arr为原始数组
  // start为遍历起始位置
  // result保存结果，为一维数组
  // count为result数组的索引值，起辅助作用
  // NUM为要选取的元素个数
  // arr_len为原始数组的长度，为定值

  let chooseN = function (newarr, start, result, count, Num, arrLen) {
    let i = 0
    for (i = start; i < arrLen + 1 - count; i++) {
      result[count - 1] = i
      if (count - 1 === 0) {
        let rr = []
        for (let j = Num - 1; j >= 0; j--) {
          if (arr[result[j]].length > 0) {
            rr.push(arr[result[j]])
          }
        }
        if (rr.length === Num) {
          allResult.push(rr)
        }
      } else {
        chooseN(newarr, i + 1, result, count - 1, Num, arrLen)
      }
    }
  }
  chooseN(newarr, 0, [], size, size, arr.length)
  return allResult
}
// 获得已选号码的索引
export const selectedIndexs = (playId, selectedIds) => {
  let allAreaIndexs = []
  let dictIds = {}
  for (let i in selectedIds) {
    dictIds[selectedIds[i]] = true
  }
  let ballInfo = config.getBallInfo(playId)
  if (ballInfo) {
    for (let i in ballInfo.areas) {
      let area = ballInfo.areas[i]
      let indexs = []
      for (let j in area.ids) {
        if (dictIds[area.ids[j]]) {
          indexs.push(j)
        }
      }
      allAreaIndexs.push(indexs)
    }
  }
  return allAreaIndexs
}
//  计算注数
export const noteNum = (playId, selectedIds) => {
  let note = 0
  let play = config.getPlayInfo(playId)
  if (play) {
    //  累计
    if (play.note_tag === 'count' || play.note_tag === 'ssc3CombSpecial') {
      note = selectedIds.length
    }
    //  前二前三
    else if (play.note_tag === 'qianN' || play.note_tag === 'qianDX') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      note = mulNoRepeat(allAreaBalls)
    }
    //  四星组12
    else if (play.note_tag === 'sscComb12') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      note = selectNoRepeat(allAreaBalls, 1, 2)
    }
    //  四星组30
    else if (play.note_tag === 'sscComb21') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      note = selectNoRepeat(allAreaBalls, 2, 1)
    }
    //  四星组60
    else if (play.note_tag === 'sscComb13') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      note = selectNoRepeat(allAreaBalls, 1, 3)
    }
    //  时时彩前二前三直选复
    else if (play.note_tag === 'sscDirect') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      note = mul(allAreaBalls)
    }
    //  时时彩前二前三组选
    else if (play.note_tag === 'sscqianNzhuhe') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      note = mulNoRepeat(allAreaBalls) * allAreaBalls.length
    }
    //  时时彩和值/跨度
    else if (play.note_tag === 'ssc2DirectSum' ||
      play.note_tag === 'ssc2Sum' ||
      play.note_tag === 'ssc2Cross' ||
      play.note_tag === 'ssc3DirectSum' ||
      play.note_tag === 'ssc3Sum' ||
      play.note_tag === 'ssc3Cross') {
      note = noteByIndex(selectedIndexs(playId, selectedIds), play.note_tag)
    }
    //  时时彩组合算法组合1
    else if (play.note_tag === 'sscComb1') {
      note = combine(selectedIndexs(playId, selectedIds)[0], 1)
    }
    //  时时彩组合算法组合2
    else if (play.note_tag === 'sscComb2') {
      note = combine(selectedIndexs(playId, selectedIds)[0], 2)
    }
    //  时时彩组合算法组合3
    else if (play.note_tag === 'sscComb3') {
      note = combine(selectedIndexs(playId, selectedIds)[0], 3)
    }
    //  时时彩组合算法组4
    else if (play.note_tag === 'sscComb4') {
      note = combine(selectedIndexs(playId, selectedIds)[0], 4)
    }
    //  时时彩组合算法组120
    else if (play.note_tag === 'sscComb5') {
      note = combine(selectedIndexs(playId, selectedIds)[0], 5)
    } else if (play.note_tag === 'sscComb6') {
      note = combine(selectedIndexs(playId, selectedIds)[0], 6)
    } else if (play.note_tag === 'sscComb7') {
      note = combine(selectedIndexs(playId, selectedIds)[0], 7)
    } else if (play.note_tag === 'sscComb8') {
      note = combine(selectedIndexs(playId, selectedIds)[0], 8)
    } else if (play.note_tag === 'dtComb2') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      if (allAreaBalls[0].length > 0) {
        note = combine(allAreaBalls[1], 1)
      }
    } else if (play.note_tag === 'dtComb3') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      if (allAreaBalls[0].length > 0) {
        note = combine(allAreaBalls[1], 3 - allAreaBalls[0].length)
      }
    } else if (play.note_tag === 'dtComb4') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      if (allAreaBalls[0].length > 0) {
        note = combine(allAreaBalls[1], 4 - allAreaBalls[0].length)
      }
    } else if (play.note_tag === 'dtComb5') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      if (allAreaBalls[0].length > 0) {
        note = combine(allAreaBalls[1], 5 - allAreaBalls[0].length)
      }
    } else if (play.note_tag === 'dtComb6') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      if (allAreaBalls[0].length > 0) {
        note = combine(allAreaBalls[1], 6 - allAreaBalls[0].length)
      }
    } else if (play.note_tag === 'dtComb7') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      if (allAreaBalls[0].length > 0) {
        note = combine(allAreaBalls[1], 7 - allAreaBalls[0].length)
      }
    } else if (play.note_tag === 'dtComb8') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      if (allAreaBalls[0].length > 0) {
        note = combine(allAreaBalls[1], 8 - allAreaBalls[0].length)
      }
    }
    //  时时彩前二/后二组选包胆
    else if (play.note_tag === 'ssc2CombBaoDan') {
      note = selectedIndexs(playId, selectedIds)[0].length * 9
    }
    //  时时彩前三/中三/后三组选包胆
    else if (play.note_tag === 'ssc3CombBaoDan') {
      note = selectedIndexs(playId, selectedIds)[0].length * 54
    }
    //  时时彩前三/中三/后三直选组和
    else if (play.note_tag === 'ssc3DicectComb') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      note = 3 * mul(allAreaBalls)
    }
    //  时时彩前三/中三/后三组选组三
    else if (play.note_tag === 'ssc3Comb3') {
      note = 2 * combine(selectedIndexs(playId, selectedIds)[0], 2)
    }
    //  时时彩任二/直选复式
    else if (play.note_tag === 'random2num') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      note = combine(selectedIds, 2)
      for (let i = 0; i < allAreaBalls.length; i++) {
        let area = allAreaBalls[i]
        if (area.length >= 2) {
          note -= combine(area, 2)
        }
      }
    }
    //  时时彩任三/直选复式
    else if (play.note_tag === 'random3num') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let arr = choose(allAreaBalls, 3)
      for (let i = 0; i < arr.length; i++) {
        let temp = arr[i]
        let tempNum = 1
        for (let j = 0; j < temp.length; j++) {
          tempNum *= temp[j].length
        }
        note += tempNum
      }
      //  时时彩任四/直选复式
    } else if (play.note_tag === 'random4num') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let arr = choose(allAreaBalls, 4)
      for (let i = 0; i < arr.length; i++) {
        let temp = arr[i]
        let tempNum = 1
        for (let j = 0; j < temp.length; j++) {
          tempNum *= temp[j].length
        }
        note += tempNum
      }
    }
    //  时时彩任二/直选和值
    else if (play.note_tag === 'r2DirectSum') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let a = combine(allAreaBalls[0], 2)
      let selectIndexs = selectedIndexs(playId, selectedIds)
      selectIndexs[0] = []
      let b = noteByIndex(selectIndexs, 'ssc2DirectSum')
      note = a * b
    }
    //  时时彩任三/直选和值
    else if (play.note_tag === 'r3DirectSum') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let a = combine(allAreaBalls[0], 3)
      let selectIndexs = selectedIndexs(playId, selectedIds)
      selectIndexs[0] = []
      let b = noteByIndex(selectIndexs, 'ssc3DirectSum')
      note = a * b
    }
    //  时时彩任二/组选复式
    else if (play.note_tag === 'r2Combnum') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let a = combine(allAreaBalls[0], 2)
      let selectIndexs = selectedIndexs(playId, selectedIds)
      selectIndexs[0] = []
      let b = combine(selectIndexs, 2)
      note = a * b
    }
    //  时时彩任二/组选和值
    else if (play.note_tag === 'r2ssc2Sum') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let a = combine(allAreaBalls[0], 2)
      let selectIndexs = selectedIndexs(playId, selectedIds)
      selectIndexs[0] = []
      let b = noteByIndex(selectIndexs, 'ssc2Sum')
      note = a * b
    }
    //  时时彩任三/组三复式
    else if (play.note_tag === 'r3Comb3') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let a = combine(allAreaBalls[0], 3)
      let selectIndexs = selectedIndexs(playId, selectedIds)
      selectIndexs[0] = []
      let b = 2 * combine(selectIndexs, 2)
      note = a * b
    }
    //  时时彩任四/组24
    else if (play.note_tag === 'r4Comb24') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let a = combine(allAreaBalls[0], 4)
      let selectIndexs = selectedIndexs(playId, selectedIds)
      selectIndexs[0] = []
      let b = combine(selectIndexs, 4)
      note = a * b
    }
    //  时时彩任四/组12
    else if (play.note_tag === 'r4Comb12') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let a = combine(allAreaBalls[0], 4)
      let b = selectNoRepeat(allAreaBalls.slice(1), 1, 2)
      note = a * b
    }
    //  时时彩任三/组六复式
    else if (play.note_tag === 'r3Comb6') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let a = combine(allAreaBalls[0], 3)
      let b = combine(allAreaBalls[1], 3)
      note = a * b
    }
    //  时时彩任四/组六
    else if (play.note_tag === 'r4Comb2') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let a = combine(allAreaBalls[0], 4)
      let b = combine(allAreaBalls[1], 2)
      note = a * b
    }
    //  时时彩任四/组选四
    else if (play.note_tag === 'r4Comb4') {
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let a = combine(allAreaBalls[0], 4)
      let b = mulNoRepeat(allAreaBalls.slice(1))
      note = a * b
    }
    //  重庆六合彩总金额
    else if (play.note_tag === 'lhcMoney') {
      note = selectedIds.length
    }
    //  连肖胆拖
    else if (play.note_tag.indexOf('xiaodt') === 1) {
      let num = Number(play.note_tag.substr(0, 1)) - 1
      let allAreaBalls = ballCmdsWithArea(playId, selectedIds)
      let dan = combine(allAreaBalls[0], num)
      let tuoNum = allAreaBalls[1].length
      note = dan * tuoNum
    }
    //  二全中生肖对碰
    else if (play.note_tag === '2xiaopen') {
      if (selectedIds.length >= 2) {
        let ballinfo = config.getBallInfo(playId)
        let calendar = helperHK.generateYearZodiac(new Date(), playId)
        let a = calendar[Number(ballinfo.balls[selectedIds[0]].cmd)]
        let b = calendar[Number(ballinfo.balls[selectedIds[1]].cmd)]
        note = a.length * b.length
      }
    }
    //  二全中尾数
    else if (play.note_tag === '2weipen') {
      if (selectedIds.length >= 2) {
        let ballinfo = config.getBallInfo(playId)
        let a = ballinfo.balls[selectedIds[0]].subballs
        let b = ballinfo.balls[selectedIds[1]].subballs
        note = a.length * b.length
      }
    }
    //  自选不中
    else if (play.note_tag.indexOf('nocount') === 0) {
      let num = Number(play.note_tag.substr(7, play.note_tag.length))
      if (selectedIds.length >= num) {
        note = 1
      }
    }
    //  二全中混合对碰
    else if (play.note_tag === '2hunpen') {
      if (selectedIds.length >= 2) {
        let ballinfo = config.getBallInfo(playId)
        let calendar = helperHK.generateYearZodiac(new Date(), playId)
        let a = []
        let b = []
        if (String(selectedIds[0]).indexOf('1') === 0) {
          a = calendar[Number(ballinfo.balls[selectedIds[0]].cmd)]
        } else {
          a = ballinfo.balls[selectedIds[0]].subballs
        }

        if (String(selectedIds[1]).indexOf('1') === 0) {
          b = calendar[Number(ballinfo.balls[selectedIds[1]].cmd)]
        } else {
          b = ballinfo.balls[selectedIds[1]].subballs
        }
        let seta = new Set(a)
        let setb = new Set(b)
        let intersectionSet = new Set([...seta].filter(x => setb.has(x)))
        note = a.length * b.length - (intersectionSet.size > 0 ? 1 : 0)
      }
    }
    //  单式
    else if (play.simplex === 1 && selectedIds.length !== 0) {
      note = selectedIds.length > 1 ? selectedIds.length - 1 : 0
      if (play.note_tag === 'random2Zxds') {
        let selects = selectedIds[0].split('')
        let a = combine(selects, 2)
        note = a * note
      } else if (play.note_tag === 'random3Zxds') {
        let selects = selectedIds[0].split('')
        let a = combine(selects, 3)
        note = a * note
      } else if (play.note_tag === 'random4Zxds') {
        let selects = selectedIds[0].split('')
        let a = combine(selects, 4)
        note = a * note
      } else {
        note = selectedIds.length
      }
    }
  }

  return Number(Number(note).toFixed(0))
}
// 选号界面中显示的赔率
export const getPlaySliderOdds = (mainPlayId, subPlayid, resId, ballId) => {
  return getPlayOdds(mainPlayId, subPlayid, resId, ballId, getCurrentPlayRebate(mainPlayId))
}

export const createPlan = (mainPlayId, planMultx, termCode, termPart, changeMultix) => {
  const { hotData } = useHot()
  const { userInfo, tenantInfo } = hotData
  let tid = tenantInfo.value.tid
  let acctId = userInfo.value.acct_id
  let custId = userInfo.value.cust_id
  let custCode = userInfo.value.cust_code
  let nickName = userInfo.value.nickname
  let mainPlay = config.getPlayInfo(mainPlayId)
  let plan = {} as any
  //  主玩法
  plan.plan_type = 1
  plan.is_com = 0
  plan.acct_id = acctId
  //  PROFITOR_ACCT_ID
  plan.profitor_acct_id = acctId
  plan.founder_id = custId
  plan.founder_code = custCode
  plan.cust_id = custId
  plan.cust_code = custCode
  plan.cust_nickname = nickName
  plan.profitor_nickname = nickName
  plan.profitor_id = custId
  plan.profitor_code = custCode
  plan.chan_code = config.chanCode
  plan.play_id = mainPlayId
  plan.play_cname = mainPlay.play_cname
  plan.play_name = mainPlay.play_name
  plan.term_code = termCode
  plan.term_cnt = 1 //  追号用

  plan.splay_id = 0 //  随便传
  plan.splay_name = '0' //  随便传
  plan.splay_cname = '0' //  随便传

  plan.div_num = 0 //  合买用
  plan.base_num = 0 //  合买用
  plan.finish_num = 0 //  合买用
  plan.comsi_pct = 0 //  合买用

  plan.pub_type = 2
  plan.tid = tid
  plan.term_part = termPart
  // 特殊处理快三倍数问题
  plan.multix = changeMultix ? 1 : planMultx

  plan.room_level = 0
  plan.play_room_id = 0
  plan.disp_flag = 1
  plan.amount = 0
  plan.note_num = 0 //  注数
  plan.playPlanTickets = []
  return plan
}
//  获得号码篮(从号码栏投注)
export const getBasketItemCMD = (mainPlayId, options, basketItem) => {
  return getSelectedIdsCMD(basketItem.subplayid, basketItem.ball_ids, basketItem.note, basketItem.multx)
}
//  获得指定号码列表的指令（快速投注）
export const getSelectedIdsCMD = (subPlayId, selectedIds, note, multx) => {
  let subplay = config.getPlayInfo(subPlayId)
  let cmd = subPlayId + '#'
  let strArr = []

  //  单式投注
  if (subplay.simplex) {
    strArr = selectedIds
  } else {
    let allAreaBalls = ballsWithArea(subPlayId, selectedIds)
    //  是否合并所有分区
    if (subplay.is_combine_cmd) {
      let tmpArr = allAreaBalls
      allAreaBalls = [
        []
      ]
      for (let x in tmpArr) {
        for (let y in tmpArr[x]) {
          allAreaBalls[0].push(tmpArr[x][y])
        }
      }
    }
    for (let i in allAreaBalls) {
      let balls = allAreaBalls[i]
      let str = ''
      for (let j in balls) {
        str += balls[Number(j)].cmd
      }

      strArr.push(str)
    }
  }
  if (subplay && subplay.xnb && selectedIds && selectedIds[0]) {
    cmd = subPlayId + selectedIds[0] + '#' + selectedIds[0] + '*' + note + '*' + multx
  } else {
    cmd += strArr.join(',') + '*' + note + '*' + multx
  }
  return cmd
}
//  子玩法是否是对碰类型
export const isPairBall = (subPlayId) => {
  let play = config.getPlayInfo(subPlayId)
  return play.cmd_pair_ball
}

export const sliptPlanWithSubPlay = (plan) => {
  let planMaps = {}

  for (let i in plan.lineTickets) {
    for (let j in plan.lineTickets[i]) {
      let ticket = config.deepCopy(plan.lineTickets[i][j]) as any

      //  一个ticket一个方案
      let planKey = 'plankey-' + i
      let subPlan = planMaps[planKey]
      if (subPlan == null) {
        subPlan = config.deepCopy(plan)
        subPlan.splay_id = ticket.play_id
        subPlan.splay_name = ticket.play_name
        subPlan.splay_cname = ticket.play_cname
        subPlan.playPlanTickets = []
        subPlan.com_amount = 0
        subPlan.amount = 0
        subPlan.note_num = 0 //  注数
        planMaps[planKey] = subPlan
      }
      subPlan.playPlanTickets.push(ticket)
    }
  }
  let playArr = []
  for (let i in planMaps) {
    let subPlan = planMaps[i]
    delete subPlan.lineTickets
    for (let j in subPlan.playPlanTickets) {
      let ticket = subPlan.playPlanTickets[j]
      subPlan.note_num += ticket.note_num
      subPlan.amount += accMul(Number(accMul(ticket.note_num, ticket.note_price, 0)), ticket.multx, 0)
    }
    subPlan.amount = Number(accMul(subPlan.amount, subPlan.multix, 0))
    subPlan.per_amount = Number(subPlan.amount)

    //  追号
    if (subPlan.playPlans) {
      let amount = 0
      subPlan.amount = subPlan.amount / subPlan.multix
      for (let k in subPlan.playPlans) {
        let chasePlan = subPlan.playPlans[k]
        chasePlan.amount = subPlan.amount * chasePlan.multix
        amount += chasePlan.amount
      }
      subPlan.amount = Number(amount)
      subPlan.per_amount = Number(subPlan.amount)
      subPlan.multix = plan.multix
    }
    //  PC00
    let play = config.getPlayInfo(subPlan.play_id)
    if (play && play.cat === 'PC00') {
      let ballInfo = config.getBallInfo(subPlan.splay_id)
      let isComAmount = false
      for (let x in subPlan.playPlanTickets) {
        let ticket = subPlan.playPlanTickets[x]
        let ball = null
        let cmd = ticket.play_cmd.split('#')[1]
        cmd = cmd.split('*')[0]
        for (let ballidx in ballInfo.balls) {
          if (ballInfo.balls[ballidx].cmd === cmd) {
            ball = ballInfo.balls[ballidx]
          }
        }
        if (ball.is_com_amount) {
          isComAmount = true
          break
        }
      }
      subPlan.com_amount = isComAmount ? subPlan.amount : 0
    }
    playArr.push(subPlan)
  }
  return playArr
}
//  过滤单式重复号码
export const filterSingleSameSelectIds = (mainPlayId, subPlayId, inArr) => {
  let play = config.getPlayInfo(subPlayId)
  if (play === null || !play.simplex) {
    return inArr
  }
  if (play.filterSingle) {
    let outArr = []
    let keys = {}
    for (let i in inArr) {
      let numArr = []
      for (let j = 0; j < inArr[i].length; j += play.numWidth) {
        let str = inArr[i].substring(j, j + play.numWidth)
        numArr.push(str)
      }
      let str = numArr.sort().join('')
      if (!keys[str]) {
        keys[str] = true
        outArr.push(str)
      }
    }
    return outArr
  }
  return inArr
}
// 获得PC蛋蛋赔率说明的数据
export const getPC00Prices = (playid, roomLevel) => {
  let titles = ['极值（极大、极小)', '小单|大双', '大单|小双', '大小单双', '红绿蓝', '豹子', '0,27', '1,26', '2,25', '3,24', '4,23', '5,22', '6,7,8,9,10,11,16,17,18,19,20,21', '12,13,14,15']
  let values = []
  values.push((getBallPrices(playid + '001', roomLevel, 9) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '001', roomLevel, 7) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '001', roomLevel, 5) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '001', roomLevel, 3) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '002', roomLevel, 1) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '003', roomLevel, 0) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '000', roomLevel, 0) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '000', roomLevel, 1) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '000', roomLevel, 2) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '000', roomLevel, 3) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '000', roomLevel, 4) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '000', roomLevel, 5) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '000', roomLevel, 6) * 0.001).toFixed(3))
  values.push((getBallPrices(playid + '000', roomLevel, 12) * 0.001).toFixed(3))
  return { titles: titles, value: values }
}
export const getTicketCodeStr = (ticket) => {
  let codeStr = ''
  let selectedIds = ticket.num
  let play = config.getPlayInfo(ticket.play_id)

  let ballArr = []
  if (play.note_tag === 'lhcMoney') {
    let ballIds = JSON.parse(selectedIds)
    let ballinfo = config.getBallInfo(ticket.play_id).balls
    for (let i in ballIds) {
      if (Object.prototype.hasOwnProperty.call(ballIds, i)) {
        ballArr.push(ballinfo[i].name + ':' + ballIds[i] + '元')
      }
    }
    codeStr += ballArr.join('|')
  }
  //  单式
  else if (play.simplex) {
    let ballInfo = config.getBallInfo(ticket.play_id)
    codeStr = ticket.play_cmd
    let codeArr = null
    if (codeStr.length > 0) {
      codeArr = codeStr.split('#')
      codeStr = (codeArr && codeArr.length > 1) ? codeArr[1] : codeStr
    }
    if (codeStr.length > 0) {
      codeArr = codeStr.split('*')
      codeStr = (codeArr && codeArr.length > 0) ? codeArr[0] : codeStr
    }

    let ballIds = codeStr.split(',')
    if (ballInfo.areas[0].isoption) {
      let checkArea = ballInfo.areas[0]
      if (checkArea.isoption && ballIds.length > 0) {
        codeStr = ' '
        let checkBalls = ballIds[0].split('')
        for (let chIdx in checkBalls) {
          let ballId = Number(checkArea.baseballid) + Number((checkBalls[chIdx]))
          codeStr += ballInfo.balls[ballId].name
        }
        let selectNums = []
        for (let ballIdx in ballIds) {
          if (Number(ballIdx) > 0) {
            selectNums.push(ballIds[ballIdx])
          }
        }
        codeStr += selectNums.join(',')
      }
    }
  } else if (ticket.num && ticket.num.length > 0) {
    let ballInfos = config.getBallInfo(ticket.play_id)
    let ballIds = JSON.parse(selectedIds)
    ballIds = ballIds[0]
    let allAreaBalls = ballsWithArea(ticket.play_id, ballIds)

    if (play.cmd_pair_ball) {
      ballArr = Object.assign([], ballIds)
    } else {
      for (let i in allAreaBalls) {
        let areaInfo = ballInfos.areas[i]
        let balls = allAreaBalls[i]
        let strTmpArr = []
        for (let j in balls) {
          let ball = balls[j]
          if (ball) {
            strTmpArr.push(ball.name)
          }
        }
        if (play.isBpan) {
          if (strTmpArr.length > 0) {
            codeStr += areaInfo.show + '   :   \v\v'
            ballArr.push(strTmpArr.join(','))
          }
        } else if (strTmpArr.length > 0) {
          if (areaInfo.show && areaInfo.show.length > 0) {
            ballArr.push(areaInfo.show + ':' + strTmpArr.join(','))
          } else {
            ballArr.push(strTmpArr.join(','))
          }
        }
      }
    }
    codeStr += ballArr.join('|')
  }

  return codeStr
}