// 彩种公共配置
export default {
  // 自有A盘-赛车
  A_SC5F: { cat: 'PK10', pan: 'A', strategy: 'PK10', play_cname: '赛车五分彩', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', termlong: 11 },
  A_SC3F: { cat: 'PK10', pan: 'A', strategy: 'PK10', play_cname: '赛车三分彩', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', termlong: 11 },
  A_SC1F: { cat: 'PK10', pan: 'A', strategy: 'PK10', play_cname: '赛车分分彩', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', termlong: 11 },
  A_AZSC5F: { cat: 'PK10', pan: 'A', strategy: 'PK10', play_cname: '澳洲赛车', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', termlong: 11 },
  A_XYSC5F: { cat: 'PK10', pan: 'A', strategy: 'PK10', play_cname: '幸运赛车', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', termlong: 11 },
  A_OZSC5F: { cat: 'PK10', pan: 'A', strategy: 'PK10', play_cname: '欧洲赛车', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', termlong: 11 },
  A_MZSC5F: { cat: 'PK10', pan: 'A', strategy: 'PK10', play_cname: '美洲赛车', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', termlong: 11 },
  A_YZSC5F: { cat: 'PK10', pan: 'A', strategy: 'PK10', play_cname: '亚洲赛车', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', termlong: 11 },
  A_YGSC5F: { cat: 'PK10', pan: 'A', strategy: 'PK10', play_cname: '英国赛车', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', termlong: 11 },

  A_FC3D: { cat: 'FC3D', pan: 'A', strategy: 'PK10', play_cname: '福彩3D', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', termlong: 11 },

  // 自有A盘-快3
  A_K35F: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '五分快三', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_K33F: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '三分快三', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_K31F: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '分分快三', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_SCK35F: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '四川快三', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_QHK35F: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '青海快三', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_YNK33F: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '云南快三', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_SXK35F: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '山西快三', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_HNK310F: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '河南快三', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_NXK310F: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '宁夏快三', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_JLK3: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '吉林快三', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_XJK3: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '新疆快三', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_TWK3: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '台湾快三', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_SAXT1: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '十分钟系统1', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_SAXT2: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '十分钟系统2', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_SAXT3: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '十分钟系统3', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_SAXT4: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '十分钟系统4', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_WFXT1: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '五分钟系统1', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_SFXT1: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '三分钟系统1', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_SFXT2: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '三分钟系统2', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_XT20MINU1: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '系统快三1', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_XT20MINU2: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '系统快三2', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_XT20MINU3: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '系统快三3', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_XT20MINU4: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '系统快三4', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_XT20MINU5: { cat: 'K3', pan: 'A', strategy: 'K3', play_cname: '系统快三5', time: ['00:00:00', '24:00:00'], page: 'k3', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },

  C_BTC1F: { cat: 'XNB', pan: 'A', strategy: 'XNB', play_cname: 'BTC1分彩', time: ['00:00:00', '24:00:00'], page: 'xnb', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  C_BTC3F: { cat: 'XNB', pan: 'A', strategy: 'XNB', play_cname: 'BTC3分彩', time: ['00:00:00', '24:00:00'], page: 'xnb', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  C_BTC5F: { cat: 'XNB', pan: 'A', strategy: 'XNB', play_cname: 'BTC5分彩', time: ['00:00:00', '24:00:00'], page: 'xnb', _page: 'pk10', tip: '24小时在线购彩', type: 2, hehz: true },
  C_BTC10F: { cat: 'XNB', pan: 'A', strategy: 'XNB', play_cname: 'BTC10分彩', time: ['00:00:00', '24:00:00'], page: 'xnb', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  C_ETH1F: { cat: 'XNB', pan: 'A', strategy: 'XNB', play_cname: 'ETH1分彩', time: ['00:00:00', '24:00:00'], page: 'xnb', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  C_ETH3F: { cat: 'XNB', pan: 'A', strategy: 'XNB', play_cname: 'ETH3分彩', time: ['00:00:00', '24:00:00'], page: 'xnb', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  C_ETH5F: { cat: 'XNB', pan: 'A', strategy: 'XNB', play_cname: 'ETH5分彩', time: ['00:00:00', '24:00:00'], page: 'xnb', _page: 'pk10', tip: '24小时在线购彩', type: 2, hehz: true },
  C_ETH10F: { cat: 'XNB', pan: 'A', strategy: 'XNB', play_cname: 'ETH10分彩', time: ['00:00:00', '24:00:00'], page: 'xnb', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  C_DOGE1F: { cat: 'XNB', pan: 'A', strategy: 'XNB', play_cname: 'DOGE1分彩', time: ['00:00:00', '24:00:00'], page: 'xnb', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  C_DOGE3F: { cat: 'XNB', pan: 'A', strategy: 'XNB', play_cname: 'DOGE3分彩', time: ['00:00:00', '24:00:00'], page: 'xnb', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  C_DOGE5F: { cat: 'XNB', pan: 'A', strategy: 'XNB', play_cname: 'DOGE5分彩', time: ['00:00:00', '24:00:00'], page: 'xnb', _page: 'pk10', tip: '24小时在线购彩', type: 2, hehz: true },
  C_DOGE10F: { cat: 'XNB', pan: 'A', strategy: 'XNB', play_cname: 'DOGE10分彩', time: ['00:00:00', '24:00:00'], page: 'xnb', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  C_QUKUAI1F: { cat: 'QKL', pan: 'A', strategy: 'QKL', play_cname: '区块1分彩', time: ['00:00:00', '24:00:00'], page: 'qkl', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  C_QUKUAI3F: { cat: 'QKL', pan: 'A', strategy: 'QKL', play_cname: '区块3分彩', time: ['00:00:00', '24:00:00'], page: 'qkl', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  C_QUKUAI5F: { cat: 'QKL', pan: 'A', strategy: 'QKL', play_cname: '区块5分彩', time: ['00:00:00', '24:00:00'], page: 'qkl', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  C_QUKUAI10F: { cat: 'QKL', pan: 'A', strategy: 'QKL', play_cname: '区块10分彩', time: ['00:00:00', '24:00:00'], page: 'qkl', _page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },

  A_GD115: { cat: '115', pan: 'A', strategy: '115', play_cname: '广东11选5', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_SH115: { cat: '115', pan: 'A', strategy: '115', play_cname: '上海11选5', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_JX115: { cat: '115', pan: 'A', strategy: '115', play_cname: '江西11选5', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },
  A_SD115: { cat: '115', pan: 'A', strategy: '115', play_cname: '山东11选5', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', type: 2, color: true },

  // 自有A盘-飞艇
  A_FT5F: { cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '飞艇五分彩', time: ['13:04:00', '4:04:00'], page: 'pk10', _page: 'pk10', tip: '13:09:00—04:04:00', type: 2, termlong: 9 },
  A_FT3F: { cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '飞艇三分彩', time: ['00:00:00', '24:00:00'], page: 'pk10', _page: 'pk10', tip: '24小时在线购彩', type: 2, termlong: 9 },
  A_FT1F: { cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '飞艇分分彩', time: ['00:00:00', '24:00:00'], page: 'pk10', _page: 'pk10', tip: '24小时在线购彩', type: 2, termlong: 9 },
  A_YZFT5F: { cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '亚洲飞艇', time: ['00:00:00', '24:00:00'], page: 'pk10', _page: 'pk10', tip: '24小时在线购彩', type: 2, termlong: 9 },
  A_OZFT5F: { cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '欧洲飞艇', time: ['00:00:00', '24:00:00'], page: 'pk10', _page: 'pk10', tip: '24小时在线购彩', type: 2, termlong: 9 },
  A_MZFT5F: { cat: 'XYFT', pan: 'A', strategy: 'XYFT', play_cname: '美洲飞艇', time: ['00:00:00', '24:00:00'], page: 'pk10', _page: 'pk10', tip: '24小时在线购彩', type: 2, termlong: 9 },

  // 自有A盘-时时彩
  A_SSC5F: { cat: 'SSC', pan: 'A', strategy: 'SSC', play_cname: '五分时时彩', time: ['00:00:00', '24:00:00'], page: 'pk10', _page: 'pk10', tip: '24小时在线购彩' },
  A_SSC3F: { cat: 'SSC', pan: 'A', strategy: 'SSC', play_cname: '三分时时彩', time: ['00:00:00', '24:00:00'], page: 'pk10', _page: 'pk10', tip: '24小时在线购彩' },
  A_SSC1F: { cat: 'SSC', pan: 'A', strategy: 'SSC', play_cname: '分分时时彩', time: ['00:00:00', '24:00:00'], page: 'pk10', _page: 'pk10', tip: '24小时在线购彩' },
  A_CQSS: { cat: 'SSC', pan: 'A', strategy: 'SSC', play_cname: '重庆时时彩', time: ['00:00:00', '24:00:00'], page: 'pk10', _page: 'pk10', tip: '24小时在线购彩' },
  A_XJSS: { cat: 'SSC', pan: 'A', strategy: 'SSC', play_cname: '新疆时时彩', time: ['00:00:00', '24:00:00'], page: 'pk10', _page: 'pk10', tip: '24小时在线购彩' },

  // 自有A盘-PC蛋蛋
  A_PC005F: { cat: 'PC00', pan: 'A', strategy: 'PC00', play_cname: '幸运28', time: ['00:00:00', '24:00:00'], tip: '24小时在线购彩', type: 1 },

  // // 自有B盘-赛车
  // B_SC5F: { cat: 'PK10JDC', pan: 'B', strategy: 'PK10JDC', play_cname: '赛车五分彩B盘', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },
  // B_SC2F: { cat: 'PK10JDC', pan: 'B', strategy: 'PK10JDC', play_cname: '赛车二分彩B盘', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },
  // B_SC1F: { cat: 'PK10JDC', pan: 'B', strategy: 'PK10JDC', play_cname: '赛车分分彩B盘', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },
  //
  // // 自有B盘-飞艇
  // B_FT5F: { cat: 'XYFTJDC', pan: 'B', strategy: 'XYFTJDC', play_cname: '飞艇五分彩B盘', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', type: 2, termlong: 9 },
  // B_FT2F: { cat: 'XYFTJDC', pan: 'B', strategy: 'XYFTJDC', play_cname: '飞艇二分彩B盘', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', type: 2, termlong: 9 },
  // B_FT1F: { cat: 'XYFTJDC', pan: 'B', strategy: 'XYFTJDC', play_cname: '飞艇分分彩B盘', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', type: 2, termlong: 9 },

  // 自有C盘-时时彩
  C_SSC5F: { cat: 'SSCJDC', pan: 'C', strategy: 'SSCJDC', play_cname: '五分时时彩', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },
  C_SSC2F: { cat: 'SSCJDC', pan: 'C', strategy: 'SSCJDC', play_cname: '二分时时彩', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },
  C_SSC1F: { cat: 'SSCJDC', pan: 'C', strategy: 'SSCJDC', play_cname: '一分时时彩', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },

  // C盘自有-赛车
  C_SC5F: { cat: 'PK10JDC', pan: 'C', strategy: 'PK10JDC', play_cname: '赛车五分彩信用', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },
  C_SC3F: { cat: 'PK10JDC', pan: 'C', strategy: 'PK10JDC', play_cname: '赛车三分彩信用', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },
  C_SC1F: { cat: 'PK10JDC', pan: 'C', strategy: 'PK10JDC', play_cname: '赛车分分彩信用', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩', termlong: 12 },

  C_YZSC5F: { cat: 'PK10JDC', pan: 'C', strategy: 'PK10JDC', play_cname: '亚洲赛车信用', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },
  C_OZSC5F: { cat: 'PK10JDC', pan: 'C', strategy: 'PK10JDC', play_cname: '欧洲赛车信用', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },
  C_MZSC5F: { cat: 'PK10JDC', pan: 'C', strategy: 'PK10JDC', play_cname: '美洲赛车信用', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },
  C_XYSC5F: { cat: 'PK10JDC', pan: 'C', strategy: 'PK10JDC', play_cname: '幸运赛车信用', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },
  C_AZSC5F: { cat: 'PK10JDC', pan: 'C', strategy: 'PK10JDC', play_cname: '澳洲五分赛车信用', time: ['00:00:00', '24:00:00'], page: 'pk10', tip: '24小时在线购彩' },

  // C盘自有-六合
  C_MS5F: { cat: 'LHCJDC', pan: 'C', strategy: 'LHCJDC', play_cname: '五分六合彩', time: ['00:00:00', '24:00:00'], page: 'hklottery', tip: '24小时在线购彩' },
  C_MS3F: { cat: 'LHCJDC', pan: 'C', strategy: 'LHCJDC', play_cname: '二分六合彩', time: ['00:00:00', '24:00:00'], page: 'hklottery', tip: '24小时在线购彩' },
  C_MS1F: { cat: 'LHCJDC', pan: 'C', strategy: 'LHCJDC', play_cname: '分分六合彩', time: ['00:00:00', '24:00:00'], page: 'hklottery', tip: '24小时在线购彩' },

  // 印度彩
  C_TCLLONI2F: { cat: 'YDC', pan: 'C', strategy: 'YDC', play_cname: 'Loni', time: ['00:00:00', '24:00:00'], page: 'ydc', tip: '24小时在线购彩' },
  C_TCLPARTIRY3F: { cat: 'YDC', pan: 'C', strategy: 'YDC', play_cname: 'Partiry', time: ['00:00:00', '24:00:00'], page: 'ydc', tip: '24小时在线购彩' },
  C_TCLSAPRE1F: { cat: 'YDC', pan: 'C', strategy: 'YDC', play_cname: 'Sapre', time: ['00:00:00', '24:00:00'], page: 'ydc', tip: '24小时在线购彩' },
  C_TCLBCONE5F: { cat: 'YDC', pan: 'C', strategy: 'YDC', play_cname: 'Bcone', time: ['00:00:00', '24:00:00'], page: 'ydc', tip: '24小时在线购彩' },
}
