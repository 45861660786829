import HmacMD5 from 'crypto-js/hmac-md5';
import config from '/@/kernel-mn/config';
import { useXianLuStore } from '/@/kernel-mn/stores/xianlu';
import RNEvent from '/@/kernel-mn/utils/RNEvent';
import { useHot } from '/@/kernel-mn/stores/useHot';
import $ from 'jquery';

export const utilsTotal = {
  moneyToFloat: (num, d = 0) => {
    return accMul(num, 0.001, d);
  },
  dateFormat: (t, style = 1) => {
    let d = new Date();
    d.setTime(t);
    if (style === 2) {
      return `${d.getFullYear()}/${padLeadingZero(d.getMonth() + 1)}/${padLeadingZero(d.getDate())}`;
    } else if (style === 21) {
      // style === 2 的变种，只是改 / 为 -
      return `${d.getFullYear()}-${padLeadingZero(d.getMonth() + 1)}-${padLeadingZero(d.getDate())}`;
    } else if (style === 3) {
      return `${d.getFullYear()}-${padLeadingZero(d.getMonth() + 1)}`;
    } else if (style === 4) {
      return `${d.getFullYear()}-${padLeadingZero(d.getMonth() + 1)}-${padLeadingZero(d.getDate())} ${padLeadingZero(d.getHours())}:${padLeadingZero(d.getMinutes())}`;
    } else if (style === 5) {
      return `${d.getFullYear()}/${padLeadingZero(d.getMonth() + 1)}/${padLeadingZero(d.getDate())} ${padLeadingZero(d.getHours())}:${padLeadingZero(d.getMinutes())}:${padLeadingZero(
        d.getSeconds()
      )}`;
    }
    return `${d.getFullYear()}-${padLeadingZero(d.getMonth() + 1)}-${padLeadingZero(d.getDate())} ${padLeadingZero(d.getHours())}:${padLeadingZero(d.getMinutes())}:${padLeadingZero(d.getSeconds())}`;
  }
};

// 嵌套对象扁平处理
export const flatObj = o => {
  const res = {};
  const flat = (obj, preKey = '') => {
    Object.entries(obj).forEach(([key, value]) => {
      let newKey = key;
      if (preKey) {
        newKey = `${Array.isArray(obj) ? `[$(newKey)]` : `${newKey}`}`;
      }
      if (value && typeof value === 'object') {
        return flat(value, newKey);
      }
      res[newKey] = value;
    });
  };
  flat(o);
  return res;
};

export const moneyChange = (status, balance) => {
  let acount: any = '';
  if (
    status.toLocaleLowerCase() === 'running' ||
    status.toLocaleLowerCase() === 'waiting' ||
    status.toLocaleLowerCase() === 'void' ||
    status.toLocaleLowerCase() === 'refund' ||
    status.toLocaleLowerCase() === 'reject'
  ) {
    acount = '--';
  } else if (status.toLocaleLowerCase() === 'draw') {
    acount = 0;
  } else {
    acount = accMul(balance, 0.001);
  }
  return acount;
};

// 转换开奖信息字符串
export const convertWinCode = (winCode, specCode, playId) => {
  let retStr = '';
  let winnum = 2;
  let play = config.getPlayInfo(playId) || {};
  if (play.cat === 'SSC' || play.cat === 'LHC' || play.cat === 'K3' || play.cat === 'SSCJDC') {
    winnum = 1;
  }
  if (play.cat === 'QKL') {
    return winCode?.substring(0, 4) + '....' + winCode?.substring(winCode?.length - 4, winCode?.length);
  }
  if (winCode && winCode.length !== 0) {
    let all = 0;
    for (let i = 0; i < winCode.length; i += winnum) {
      let sstr = winCode.substring(i, i + winnum);
      if (i === 0) {
        if (play.cat === 'PC00') {
          retStr += Number(sstr);
        } else {
          retStr += sstr;
        }
      } else {
        if (play.cat === 'PC00') {
          retStr += ' + ' + Number(sstr);
        } else if (play.cat === 'SSC' || play.cat === 'LHC' || play.cat === 'SSCJDC') {
          retStr += ' ' + sstr;
        } else {
          retStr += ',' + sstr;
        }
      }
      all += Number(sstr);
    }
    if (retStr.length !== 0 && play.cat === 'PC00') {
      retStr += ' = ' + strPadLeft(all, 2);
    }
  }
  if (retStr.length !== 0 && play.cat === 'FC3D') {
    retStr = winCode;
  }
  return retStr;
};

export const getBetCode = item => {
  let playPlanTickets = item.playPlanTickets ? item.playPlanTickets[0] : {};
  let num = playPlanTickets.num ? playPlanTickets.num : '';
  let code = '';
  if (num) {
    num = JSON.parse(num);
    if (num[0] && num[0][0]) {
      code = num[0][0];
    }
  }
  let mainPlay = config.getPlayInfo(item.play_id);
  if (mainPlay && mainPlay.cat === 'XNB') {
    if (playPlanTickets.play_cname === '和值') {
      return '和值';
    }
    if (Number(code) > 9) {
      return '正投';
    }
  }
  return item.splay_cname;
};

export const convertWinCodeLeft = (winCode, specCode, playId) => {
  let retStr = '';
  let play = config.getPlayInfo(playId);
  if (winCode && winCode.length !== 0 && play) {
    for (let i = 0; i < winCode.length; i += 2) {
      let sstr = winCode.substring(i, i + 2);
      if (i === 0) {
        retStr += sstr;
      } else {
        // if (playId === 40 || playId === 41) {
        if (play.cat === 'PC00') {
          retStr += ' + ' + sstr;
        } else {
          retStr += ',' + sstr;
        }
      }
    }
    if (retStr.length !== 0 && play.cat === 'PC00') {
      retStr += ' = ';
    }
  }
  return retStr;
};

export const convertWinCodeSum = winCode => {
  let all = 0;
  if (winCode && winCode.length !== 0) {
    for (let i = 0; i < winCode.length; i += 2) {
      let sstr = winCode.substring(i, i + 2);
      all += Number(sstr);
    }
  }
  return strPadLeft(all, 2);
};

export const padLeadingZero = value => {
  return value > 9 ? value : `0${value}`;
};

// 小数点精度（）
export const fixFloat = num => {
  let m = 0;
  let s1 = num.toString();
  try {
    m += s1.split('.')[1].length;
    // eslint-disable-next-line no-empty
  } catch (e) {
  }
  return Number(s1.replace('.', '')) / Math.pow(10, m);
};

export const uuid = (len, radix) => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    /* eslint-disable */
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data. At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
    /* eslint-enable */
  }
  return uuid.join('');
};

export const isAndroid = () => {
  let ua = navigator.userAgent.toLowerCase();
  return /android/.test(ua);
};

export const isIOS = () => {
  let ua = navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(ua);
};

export const isSafari = () => {
  let ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('applewebkit') > -1 && ua.indexOf('mobile') > -1 && ua.indexOf('safari') > -1 &&
    ua.indexOf('linux') === -1 && ua.indexOf('android') === -1 && ua.indexOf('chrome') === -1 &&
    ua.indexOf('ios') === -1 && ua.indexOf('browser') === -1) {
    return true;
  } else {
    return false;
  }
}

export const isMobile = () => {
  return isIOS() || isAndroid();
};

export const isApp = () => {
  return (isIOS() || isAndroid()) && config.appVersion > 0;
};

export const isH5 = () => {
  return isIOS() || isAndroid();
};

export const getBetPlatform = () => {
  if (isAndroid()) {
    if (config.appVersion > 0) {
      return 'ANDROID';
    } else {
      return 'H5_ANDROID';
    }
  }
  if (isIOS()) {
    if (config.appVersion > 0) {
      return 'IOS';
    } else {
      return 'H5_IOS';
    }
  }
  return 'PC';
};

export const getp = p => {
  return HmacMD5(p, '7NEkojNzfkk=').toString();
};

// 数字左边补0
export const strPadLeft = (str, length) => {
  if (str.toString().length >= length) {
    return str;
  } else {
    return strPadLeft('0' + str, length);
  }
};

export const randomInteger = (low, high) => {
  return low + Math.floor(Math.random() * (high - low));
};

export const getHostName = val => {
  let url = val || (window && window.location && window.location.hostname ? window.location.hostname : '');
  if (url) {
    if (url.indexOf('http://') !== -1) {
      url = url.substring(7, url.length);
    } else if (url.indexOf('https://') !== -1) {
      url = url.substring(8, url.length);
    }
  }
  return url;
};

export const dateFormat = (t, style = 1) => {
  let d = new Date();
  d.setTime(t);
  if (style === 2) {
    return `${d.getFullYear()}/${padLeadingZero(d.getMonth() + 1)}/${padLeadingZero(d.getDate())}`;
  } else if (style === 21) {
    // style === 2 的变种，只是改 / 为 -
    return `${d.getFullYear()}-${padLeadingZero(d.getMonth() + 1)}-${padLeadingZero(d.getDate())}`;
  } else if (style === 3) {
    return `${d.getFullYear()}-${padLeadingZero(d.getMonth() + 1)}`;
  } else if (style === 4) {
    return `${d.getFullYear()}-${padLeadingZero(d.getMonth() + 1)}-${padLeadingZero(d.getDate())} ${padLeadingZero(d.getHours())}:${padLeadingZero(d.getMinutes())}`;
  } else if (style === 5) {
    return `${d.getFullYear()}/${padLeadingZero(d.getMonth() + 1)}/${padLeadingZero(d.getDate())} ${padLeadingZero(d.getHours())}:${padLeadingZero(d.getMinutes())}:${padLeadingZero(d.getSeconds())}`;
  }
  return `${d.getFullYear()}-${padLeadingZero(d.getMonth() + 1)}-${padLeadingZero(d.getDate())} ${padLeadingZero(d.getHours())}:${padLeadingZero(d.getMinutes())}:${padLeadingZero(d.getSeconds())}`;
};

// 输入时间对象 返回 年/月/日 perxp 为分隔符 默认 '/'
export const getYMD = (date: Date, perxp: string = '/') => {
  const year = date.getFullYear();
  let _month: string | number = date.getMonth() + 1;
  if (_month < 10) {
    _month = '0' + _month;
  }
  let _day: string | number = date.getDate();
  if (_day < 10) {
    _day = '0' + _day;
  }
  return year + perxp + _month + perxp + _day;
};

// 输入时间对象 返回 年/月/日 时:分:秒 perxp 为分隔符 默认 '/', ':' type = 'YMDhms'
export const getYMDHMS = (date: Date | number | string, perxp: string = '/', hxp: string = ':', type = '') => {
  // string = '2020/07/10 15:54:23' 时间字符串
  if ((typeof date).toLowerCase() === 'number' || (typeof date).toLowerCase() === 'string') {
    date = new Date(date);
  }
  date = date as Date;
  const year = date.getFullYear();
  let _month = addZero(date.getMonth() + 1);
  let _day = addZero(date.getDate());
  let h = addZero(date.getHours());
  let m = addZero(date.getMinutes());
  let s = addZero(date.getSeconds());
  if (type) {
    if (type === 'YMD') return `${year + perxp + _month + perxp + _day}`;
    if (type === 'YM') return `${year + perxp + _month}`;
    if (type === 'MD') return `${_month + perxp + _day}`;
    if (type === 'MDhms') return `${_month + perxp + _day} ${h + hxp + m + hxp + s}`;
    if (type === 'MDhm') return `${_month + perxp + _day} ${h + hxp + m}`;
    else if (type === 'Dhms') return `${_day} ${h + hxp + m + hxp + s}`;
    else if (type === 'Dhm') return `${_day} ${h + hxp + m}`;
    else if (type === 'hms') return `${h + hxp + m + hxp + s}`;
    else if (type === 'hm') return `${h + hxp + m}`;
    else if (type === 'ms') return `${m + hxp + s}`;
  }
  return `${year + perxp + _month + perxp + _day} ${h + hxp + m + hxp + s}`;
};

/**
 * 纠正日期格式 yyyy-MM-dd HH-mm-ss 为 HH-mm-ss dd-MM-yyyy的格式
 */
export const ymdhms2hmsdmy = time => {
  if (time) {
    if (String(time).length === 13) {
      //time为时间戳
      time = utilsTotal.dateFormat(time);
    }
    const tms = time.split(' ');
    if (tms[0] && tms[1]) {
      // 是 dd-MM-yyyy 的格式
      const tmp = tms[0].split('-');
      if (tmp.length === 3) {
        time = `${tms[1]} ${tmp[2]}-${tmp[1]}-${tmp[0]}`;
      } else if (tmp.length === 2) {
        time = `${tms[1]} ${tmp[1]}-${tmp[0]}`;
      }
    }
  }
  return time;
};

// 输入时间对象 返回 月/日 时:分:秒 perxp 为分隔符 默认 '/', ':'
export const getMDHMS = (date: Date | number, perxp: string = '/', hxp: string = ':') => {
  if ((typeof date).toLowerCase() === 'number') {
    date = new Date(date);
  }
  date = date as Date;
  let _month = addZero(date.getMonth() + 1);
  let _day = addZero(date.getDate());
  let h = addZero(date.getHours());
  let m = addZero(date.getMinutes());
  let s = addZero(date.getSeconds());
  return `${_month + perxp + _day} ${h + hxp + m + hxp + s}`;
};

// 少十补零
export const addZero = (num: number | string): string => (parseInt(num + '') < 10 ? '0' + num : num + '');

// 浮点数相加
export const accAdd = (arg1: any, arg2: any) => {
  let r1: number, r2: number, m: number, c: number;
  r1 = arg1.toString().split('.')[1]?.length ?? 0;
  r2 = arg2.toString().split('.')[1]?.length ?? 0;
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace('.', ''));
      arg2 = Number(arg2.toString().replace('.', '')) * cm;
    } else {
      arg1 = Number(arg1.toString().replace('.', '')) * cm;
      arg2 = Number(arg2.toString().replace('.', ''));
    }
  } else {
    arg1 = Number(arg1.toString().replace('.', ''));
    arg2 = Number(arg2.toString().replace('.', ''));
  }
  return (arg1 + arg2) / m;
};

// 浮点数相减
export const accSub = (arg1: number, arg2: number) => {
  let r1: number, r2: number, m: number, c: number;
  r1 = arg1.toString().split('.')[1]?.length ?? 0;
  r2 = arg2.toString().split('.')[1]?.length ?? 0;
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace('.', ''));
      arg2 = Number(arg2.toString().replace('.', '')) * cm;
    } else {
      arg1 = Number(arg1.toString().replace('.', '')) * cm;
      arg2 = Number(arg2.toString().replace('.', ''));
    }
  } else {
    arg1 = Number(arg1.toString().replace('.', ''));
    arg2 = Number(arg2.toString().replace('.', ''));
  }
  return (arg1 - arg2) / m;
};

// 乘法，最大保留几位小数默认为8
export const accMul = (arg1: number, arg2: number, len: number = 8, needZero: boolean = true) => {
  let m = 0;
  let s1 = arg1 ? arg1.toString() : '';
  let s2 = arg2 ? arg2.toString() : '';
  let m1 = s1.split('.')[1]?.length ?? 0;
  let m2 = s2.split('.')[1]?.length ?? 0;
  m += m1 + m2;
  let result = (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
  if (len === 0) {
    return result;
  }
  if (needZero) {
    return result.toFixed(len);
  }
  return result.toFixed(len).replace(/\.?0+$/, '');
};

// 除法 最大保留几位小数默认为8
export const accDiv = (arg1: number, arg2: number, len: number = 8) => {
  let result = arg1 / arg2;
  return result.toFixed(len);
};

// 金额乘1000
export const num2Li = (num: number, px = 1000) => num * px;

// 压缩图片
export const compressFile = (file, { ratio }, callback) => {
  let fileObj = file;
  let reader = new FileReader();
  reader.readAsDataURL(fileObj); // 转base64
  reader.onload = function (e) {
    let image: any = new Image(); // 新建一个img标签（还没嵌入DOM节点)
    image.src = e.target.result;
    image.onload = function () {
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      let imageWidth = image.width * ratio;
      let imageHeight = image.height * ratio;
      let data = '';
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      context.drawImage(image, 0, 0, imageWidth, imageHeight);
      data = canvas.toDataURL(file.type || 'image/jpeg'); // 输出压缩后的base64
      let arr = data.split(',');
      let mime = arr[0].match(/:(.*?);/)[1]; // 转成blob
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const lastIndex = fileObj.name.lastIndexOf('.');
      const newName = fileObj.name.slice(0, lastIndex);
      let files = null;
      try {
        // 这儿存在兼容性问题
        files = new window.File([new Blob([u8arr], { type: mime })], `${newName}.jpeg`, { type: 'image/jpeg' }); // 转成file
      } catch (e) {
        files = file;
      }
      callback(files, { imageWidth, imageHeight }); // 回调
    };
  };
};

// 校验密码 1：纯数字 2：数字+字母 3：数字+大小字母 4：数字+大小字母 +特殊字符 5: 内容随意,只限制6-16位长度
export const checkPwd = (str, level, min = 1, max = 6) => {
  let reg = {
    0: new RegExp(`^\\d{${min},${max}}$`),
    1: /^(?=.*\d)[0-9]{6,16}$/,
    2: /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{6,16}$/,
    3: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,16}$/,
    4: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~@//^#\-%/.+:;=*])[a-zA-Z0-9~@//^#\-%/.+:;=*]{6,16}$/,
    5: /^.{6,16}$/
  };
  return reg[level] && reg[level].test(str);
};

// 校验密码等级
export const checkPwdLevel = str => {
  let level = '1';
  let reg = {
    1: /^(?=.*\d)[0-9]{6,16}$/,
    2: /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{6,16}$/,
    3: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,16}$/,
    4: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~@//^#\-%/.+:;=*])[a-zA-Z0-9~@//^#\-%/.+:;=*]{6,16}$/,
    5: /^.{6,16}$/
  };
  for (let key in reg) {
    reg[key].test(str) && (level = key);
  }
  return level;
};

export const isRegisterUserName = s => {
  let reg = new RegExp('^[0-9a-zA-Z]{5,12}$');
  return reg.test(s);
};

// 验证邮箱
export const checkEmail = s => {
  let reg = new RegExp(/^[a-z\d]+(\.[a-z\d]+)*@([\da-z](-[\da-z])?)+(\.{1,2}[a-z]+)+$/);
  return reg.test(s);
};

// 校验是否含有空格
export const checkBlank = s => {
  let reg = new RegExp(/^[^\s]*$/);
  return !reg.test(s);
};

export const numberComma = (source: number | string, length = 3): string => {
  let sourceArr: string[] = String(source).split('.');
  sourceArr[0] = sourceArr[0].replace(new RegExp('(\\d)(?=(\\d{' + length + '})+$)', 'ig'), '$1,');
  return sourceArr.join('.');
};

/**
 * 纠正日期格式 yyyy-MM-dd为 dd-MM-yyyy的格式
 */
export const ymd2dmy = dateTime => {
  if (dateTime) {
    if (String(dateTime).indexOf('-') != -1) {
      const tmp = String(dateTime).split('-');
      if (tmp[0] && tmp[0].length === 4) {
        // 是 yyyy-MM-dd 的格式
        dateTime = `${tmp[2]}-${tmp[1]}-${tmp[0]}`;
      }
    } else if (String(dateTime).indexOf('/') != -1) {
      const tmp = String(dateTime).split('/');
      if (tmp[0] && tmp[0].length === 4) {
        // 是 yyyy-MM-dd 的格式
        dateTime = `${tmp[2]}/${tmp[1]}/${tmp[0]}`;
      }
    }
  }
  return dateTime;
};

export const checkRealName = s => {
  let reg1 = new RegExp(/^[\u4e00-\u9fa5a-zA-Z]｜[\u4e00-\u9fa5a-zA-Z]+((\·|\.|\s)[\u4e00-\u9fa5a-zA-Z])$/);
  let reg2 = new RegExp(/[`~!@#$%^&*()+-=<>?:"{}|,\\/;'[\]]/im);
  let reg3 = new RegExp(/[！#￥（）：；、“’｜《。》？【】]/im);
  return reg1.test(s) || reg2.test(s) || reg3.test(s);
};
export const banlance2str = (balance, digital = 2) => {
  if (isNaN(balance)) {
    return '0';
  }
  return (Math.round(+balance || 0) / 1000).toFixed(digital).toString();
};
// 独立序列化金币
export const fmtnum2str = (num: number) => numberComma(banlance2str(num));
// 隐藏手机号中间位数
export const displayPhoneNum = (payNo, prenum = 3, nextnum = 4, num = 4) => {
  let str1 = '*';
  payNo = payNo + '';
  const reg = new RegExp('(.{' + prenum + '})(.*)(.{' + nextnum + '})');
  payNo = payNo.replace(reg, str => {
    return str.slice(0, prenum) + str1.repeat(num) + str.slice(str.length - nextnum, str.length);
  });
  return payNo;
};
// 姓名脱敏
export const displayName = (name, len = 3, repstr = '*') => (name ? `${name.slice(0, 1)}${repstr.repeat(len)}${name.slice(-1)}` : ``);
// 邮箱脱敏 len平铺长度 repStr替换字符串样式
export const displayEmail = (name, len = 3, repstr = '*') => {
  let tmp = (name || '').split('@');
  let tname = tmp[0] || '';
  if (name) {
    tname = tname ? `${tname.slice(0, 1)}${repstr.repeat(len)}${tname.slice(-1)}` : ``;
    tname += '@' + tmp[1] || '';
  }
  return tname || name;
};
// 字符串数字保留3位小数
export const number2float = (numStr, len = 3) => {
  let exp = new RegExp('^\\d+(?:\\.\\d{0,' + len + '})?');
  return String((numStr + '').match(exp) || 0);
};

// 时间换算 将秒数换算返回 00:00:00 默认分割符 :
export const getCountDown = (num, exp = ':') => {
  let hours = '';
  let min = '';
  let sec = '';
  if (num > 0) {
    // 根据 num 算出时分秒
    const hoursTmp = Math.trunc(num / 1000 / 60 / 60);
    hours = hoursTmp < 10 ? `0${hoursTmp}` : hoursTmp + '';
    const minTmp = Math.trunc((num - hoursTmp * 1000 * 60 * 60) / 1000 / 60);
    min = minTmp < 10 ? `0${minTmp}` : minTmp + '';
    const secTmp = Math.trunc((num - hoursTmp * 1000 * 60 * 60 - minTmp * 1000 * 60) / 1000);
    sec = secTmp < 10 ? `0${secTmp}` : secTmp + '';
  }
  if (sec) {
    return hours + exp + min + exp + sec;
  } else {
    return '';
  }
};

// 生成2这之间的随机数 默认随机出整数
export const randomFloat = (min, max, len = 0) => {
  let newnum = Math.random() * (max - min) + min;
  return (len === 0 && Math.floor(newnum) + '') || newnum.toFixed(len);
};

// 保存图片方法 data是链接 或者 canvasDom元素
export const saveImg = (data: any, name, { success, error }) => {
  const mb_RNEvent = RNEvent.getInstance();
  if ((typeof data).toLowerCase() === 'string') {
    if (data.substring(0, 10) !== 'data:image') {
      let url = data;
      let ext = url.slice(url.lastIndexOf('.') + 1).toLowerCase();
      if (ext === 'jpg' || ext === 'gif' || ext === 'jpeg' || ext === 'bmp' || ext === 'png') {
        url2base64(url)
          .then(base64 => {
            startDownload(base64);
          })
          .catch(() => {
            error && error('保存图片失败');
          });
      } else {
        error && error('保存图片失败');
      }
    } else {
      startDownload(data);
    }
  } else {
    let base64 = data.toDataURL('image/png');
    startDownload(base64);
  }

  function base64url2blob(base64) {
    let parts = base64.split(';base64,');
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;
    let uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; i++) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }

  function url2base64(url) {
    return new Promise(function (resolve, reject) {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.onload = function () {
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        let ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
        let base64 = canvas.toDataURL('image/' + ext);
        resolve(base64);
      };
      img.onerror = function (err) {
        reject(err);
      };
    });
  }

  function startDownload(base64) {
    if (isApp()) {
      mb_RNEvent.sendNativeMsg('saveQRcode2:' + JSON.stringify({ base64: base64 }), '');
      mb_RNEvent.addObserver(`saveIMGKey`, `saveIMG`, strJSON => {
        mb_RNEvent.removeObserver('saveIMGKey', 'saveIMG');
        success && success(strJSON.text);
      });
    } else {
      let blob = base64url2blob(base64);
      let a = document.createElement('a');
      a.download = name;
      a.href = URL.createObjectURL(blob);
      a.click();
      success && success('保存图片成功');
    }
  }
};

export const loadJSONP = (url, callback) => {
  $.ajax({
    url: url,
    dataType: 'jsonp',
    type: 'get',
    timeout: 6000,
    complete: (req, status) => {
      if (req.status === 200 || req.status === 417 || req.status === 416) {
        callback('success');
      } else {
        callback('error');
      }
    }
  });
};

// 上传图片 H5与APP共用
// 获取图片上传线路
export const getImgToken = (payload = {}) => {
  const xianlu = useXianLuStore();
  return xianlu.reqImgToken(payload);
};

// 获取线路后的上传动作 返回上传后的URL
export const getUpLoadUrl = (payload: any = {}) => {
  const xianlu = useXianLuStore();
  return xianlu.uploadImg(payload);
};
// 上传图片公共方法 success 上传过程中正常函数 error 上传过程失败函数 loading上传过程函数
export const uploadImgPublic = ({ success, error, loading }) => {
  const mb_RNEvent = RNEvent.getInstance();
  // 上传图片
  const isapp = isApp(); // 是否是app
  // id为页面input id  appcb app上传后回调
  const onUploadClick = async (id, appcb) => {
    if (isapp) {
      let res = await getImgToken();
      if (res && res.token && res.url) {
        // app上传
        appSendPic({ token: res.token, url: res.url }, appcb);
      } else {
        error && error();
      }
    } else {
      // let id = 'file';
      const fileElem = document.getElementById(id);
      if (fileElem) {
        fileElem.click();
      }
    }
  };
  // e input文件 webcb浏览器上传回调
  const onFileChange = async (e, webcb) => {
    let file = e.target.files[0];
    /**
     * 加了111，是因为在打包过后他们不相等了
     */
    if (!(`${file.type}111` === 'image/png111' || `${file.type}111` === 'image/jpg111' || `${file.type}111` === 'image/jpeg111' || `${file.type}111` === 'image/webp111')) {
      // 检测是否是图片格式 png、jpg、webp
      error && error('请重新选择图片，支持png、jpg、webp格式');
      return;
    }
    if (file.size > 10 * 1024 * 1024) {
      // 图片超过10M不给与发送
      error && error('请选择10M以下的图片');
      return;
    }
    let ratio = 1;
    /**
     * 8-10M：除以18
     * 6-8M： 除以16
     * 4-6M： 除以14
     * 2-4M： 除以12
     * 1-2M： 除以4
     * 500kb-1M： 除以2
     */
    if (file.size > 8 * 1024 * 1024) {
      // 8-10M
      ratio = 1 / 18;
    } else if (file.size > 6 * 1024 * 1024) {
      // 6-8M
      ratio = 1 / 16;
    } else if (file.size > 4 * 1024 * 1024) {
      // 4-6M
      ratio = 1 / 14;
    } else if (file.size > 2 * 1024 * 1024) {
      // 2-4M
      ratio = 1 / 12;
    } else if (file.size > 1 * 1024 * 1024) {
      // 1-2M
      ratio = 1 / 4;
    } else if (file.size > 0.5 * 1024 * 1024) {
      // 500kb-1M
      ratio = 1 / 2;
    }
    loading && loading();
    try {
      let res: any = await getImgToken();
      if (res && res.token && res.url) {
        const tmpFunc = async (ret, HWratio, webcb) => {
          try {
            let res1 = await getUpLoadUrl({
              file: ret,
              token: res.token,
              url: res.url
            });
            success && success();
            if (res1 && res1.url) {
              // 成功上传完至图片服务器了，开始发送openfire消息
              let headPortrait = res1.url;
              if (!/^http/.test(headPortrait)) {
                headPortrait = `${location.protocol}//${headPortrait}`; // 与域名相同
              }
              webcb && webcb(headPortrait);
            }
          } catch (err) {
            console.log('上传获取url地址->', err, HWratio);
            error && error(err);
          }
        };
        compressFile(file, { ratio }, (resFile, { imageWidth, imageHeight }) => {
          tmpFunc(resFile, imageHeight / imageWidth, webcb); // 第二个参数为宽高比
        });
      }
    } catch (err) {
      console.log('上传错误：->', err);
      error && error('请求图片上传接口数据失败');
    }
  };
  const uploadImgCB = (res, err, HWratio, appcb) => {
    if (res && res.url) {
      let headPortrait = res.url;
      if (!/^http/.test(headPortrait)) {
        headPortrait = `${location.protocol}//${headPortrait}`; // 与域名相同
      }
      appcb && appcb(headPortrait);
    } else {
      error && error('请求图片上传接口数据失败');
    }
  };
  const appSendPic = ({ token, url }, appcb) => {
    // app 上传
    mb_RNEvent.sendNativeMsg('sendPicture:' + JSON.stringify({ token, url }));
    mb_RNEvent.addObserver(`${mb_RNEvent.sendPicture}Key`, mb_RNEvent.sendPicture, ({ res, err, HWratio }) => {
      mb_RNEvent.removeObserver(`${mb_RNEvent.sendPicture}Key`, mb_RNEvent.sendPicture);
      uploadImgCB(res, err, HWratio, appcb);
      success && success();
    });
    // 提示语监听
    mb_RNEvent.addObserver(`${mb_RNEvent.sendPicture}ToastKey`, `${mb_RNEvent.sendPicture}Toast`, strJSON => {
      mb_RNEvent.removeObserver(`${mb_RNEvent.sendPicture}ToastKey`, `${mb_RNEvent.sendPicture}Toast`);
      if (strJSON.type === 'text') {
        success && success(strJSON.text);
      } else if (strJSON.type === 'loading') {
        loading && loading(strJSON.text);
      }
    });
  };
  // 返回2个执行函数
  return { onUploadClick, onFileChange };
};

export const fixIOSInput = nodeName => {
  if (isIOS()) {
    if ($(nodeName)) $(nodeName).css('position', 'absolute');
  }
};

// 键盘弹出
export const inputKeyBoardUp = (value, e) => {
};

// 键盘收回
export const inputKeyBoardDown = (value, e) => {
  if (e && e.target) {
    if (isAndroid()) {
      $('body').css('transform', '');
    } else if (isIOS()) {
      fixIOSInput(null);
      setTimeout(() => {
        $('.ant-modal-mask').css('position', 'fixed');
        $('.ant-modal-wrap').css('position', 'fixed');
      }, 600);
    }
    if (e.target.blur instanceof Function) {
      e.target.blur();
    }
  } else if (value && value.target) {
    if (isAndroid()) {
      $('body').css('transform', '');
    } else if (isIOS()) {
      fixIOSInput(null);
      setTimeout(() => {
        $('.ant-modal-mask').css('position', 'fixed');
        $('.ant-modal-wrap').css('position', 'fixed');
      }, 600);
    }
  }
};

/**
 * 纠正日期格式 dd-MM-yyyy 为 yyyy-MM-dd的格式
 */
export const dmy2ymd = dateTime => {
  if (dateTime) {
    const tmp = String(dateTime).split('-');
    if (tmp[2] && tmp[2].length === 4) {
      // 是 dd-MM-yyyy 的格式
      dateTime = `${tmp[2]}-${tmp[1]}-${tmp[0]}`;
    }
    if (tmp[0] && tmp[0].length === 4) {
      // 是 yyyy-MM-dd 的格式
      dateTime = `${tmp[0]}-${tmp[1]}-${tmp[2]}`;
    }
  }
  return dateTime;
};

export const decimalToBinary = (num, Bits) => {
  // 转二进制 (数字位数)
  let resArry = Number(num).toString(2);
  if (Bits) {
    let t = '';
    for (let r = resArry.length; r < Bits; r++) {
      t += 0;
    }
    resArry = t + resArry;
  }
  return resArry;
};

export const findStrIndex = (str, cha, num) => {
  // 找到字符串在指定字符串中的第N次出现的位置的下标
  let x = str.indexOf(cha);
  for (let i = 0; i < num; i++) {
    x = str.indexOf(cha, x + 1);
  }
  return x;
};

// 科学计数数字转字符串
export const transToNumber = num => {
  if (isNaN(num)) return num;
  num = '' + num;
  num = parseFloat(num);
  let eformat = num.toExponential(); // 转换为标准的科学计数法形式（字符串）
  let tmpArray = eformat.match(/\d(?:\.(\d*))?e([+-]\d+)/); // 分离出小数值和指数值
  let number = num.toFixed(Math.max(0, (tmpArray[1] || '').length - tmpArray[2]));
  return number;
};

export const moneyString = num => {
  return Number(num).toFixed(3);
};

// 验证是不是trx的地址
export const isTrxAddress = address => {
  if (/^T(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{33}$/.test(address)) {
    return true;
  }
  return false;
};

/**
 * 经过验证的新的开始时间计算方式
 */
export const dayStartTimeNew = (time: any) => {
  if (/^\d+$/.test(time)) {
    // 传入的为时间戳
    time = getYMD(new Date(time));
  } else if (time instanceof Date) {
    // 传入的为时间对象
    time = getYMD(time);
  }
  // time 需为 yyyy-MM-dd 或 yyyy/MM/dd 的年月日时间格式
  return new Date(`${time.replace(/-/g, '/')} 00:00:00`).getTime();
};
/**
 * 经过验证的新的结束时间计算方式
 */
export const dayEndTimeNew = (time: any) => {
  if (/^\d+$/.test(time)) {
    // 传入的为时间戳
    time = getYMD(new Date(time));
  } else if (time instanceof Date) {
    // 传入的为时间对象
    time = getYMD(time);
  }
  // time 需为 yyyy-MM-dd 或 yyyy/MM/dd 的年月日时间格式
  return new Date(`${time.replace(/-/g, '/')} 23:59:59`).getTime();
};

export const firstUpperCase = (str: string) => {
  return str.toLowerCase().replace(/( |^)[a-z]/g, L => L.toUpperCase());
};

// 判断是否输入了表情
export const isEmoji = str => {
  let reg = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
  return reg.test(str);
};

export const hasUrlParams = url => {
  return url.indexOf('?') > 0 && url.indexOf('=') > 0 && url.indexOf('?') < url.indexOf('=');
};

// 银行卡号显示*****
export const displayCardcode = (payNo, len, num) => {
  let str1 = '*';
  len = len || 4;
  payNo = payNo + '';
  num = num || 8;
  const reg = new RegExp('(.{' + len + '})(.*)(.{' + len + '})');
  payNo = payNo.replace(reg, str => {
    return str.slice(0, len) + str1.repeat(num) + str.slice(str.length - len, str.length);
  });
  return payNo;
};

// 游戏黑名单过滤
export const blackListFilter = (response, blackListGame, custName) => {
  let obj = null;
  if (response && blackListGame) {
    let banGameInfo = {
      isBanUser: false,
      blacklistGame: []
    };
    blackListGame.split(';').map(blacklistItem => {
      let currentArr = blacklistItem.split(',');
      if (custName === currentArr[0]) {
        banGameInfo.isBanUser = true;
        banGameInfo.blacklistGame = currentArr.filter((cItem, cIndex) => cIndex);
      }
    });
    if (banGameInfo.isBanUser && banGameInfo.blacklistGame.length) {
      obj = response.filter(i => !banGameInfo.blacklistGame.filter(banItem => Number(i.play_id) === Number(banItem) || Number(i.id) === Number(banItem)).length);
    } else {
      obj = response;
    }
  } else {
    obj = response;
  }
  return obj;
};

export const stopTime = term => {
  let delayTime = Number(term.end_time) - Number(term.stop_sale_offset) * 1000 - Number(term.db_sys_date); //  服务器时间多久后结束
  return new Date().getTime() + delayTime;
};

export const countDownStr1 = duraction => {
  let duraction2 = duraction;
  if (duraction2 <= 0) {
    duraction2 = 0;
  }
  let dd = parseInt(String(duraction2 / 1000 / 60 / 60 / 24), 10); // 计算剩余的天数
  let hh = parseInt(String((duraction2 / 1000 / 60 / 60) % 24), 10); // 计算剩余的小时数
  let mm = parseInt(String((duraction2 / 1000 / 60) % 60), 10); // 计算剩余的分钟数
  let ss = parseInt(String((duraction2 / 1000) % 60), 10); // 计算剩余的秒数
  let strhh = strPadLeft(hh, 2);
  let strmm = strPadLeft(mm, 2);
  let strss = strPadLeft(ss, 2);

  if (dd > 0) {
    hh += 24 * dd;
    strhh = strPadLeft(hh, 2);
  }

  const obj = {
    hour: strhh,
    min: strmm,
    ss: strss
  };
  return obj;
};

export const getQQChat = qqn => {
  return `https://wpa.qq.com/msgrd?v=3&uin=${qqn}&site=qq&menu=yes`;
};

// url转对象
export const getUrlParams = url => {
  let obj = {};
  try {
    url = url.match(/\?([^#]+)/)[1];
    let arr = url.split('&');
    for (let i = 0; i < arr.length; i++) {
      let subArr = arr[i].split('=');
      let key = decodeURIComponent(subArr[0]);
      obj[key] = decodeURIComponent(subArr[1]);
    }
    return obj;
  } catch (e) {
    return obj;
  }
};
// 对象转url
export const parseUrlParams = data => {
  try {
    let tempArr = [];
    for (let i in data) {
      let key = encodeURIComponent(i);
      let value = encodeURIComponent(data[i]);
      tempArr.push(key + '=' + value);
    }
    return tempArr.join('&');
  } catch (e) {
    return '';
  }
};
export const countDownStr = duraction => {
  let duraction2 = duraction;
  if (duraction2 <= 0) {
    duraction2 = 0;
  }
  let dd = parseInt(String(duraction2 / 1000 / 60 / 60 / 24), 10); // 计算剩余的天数
  let hh = parseInt(String((duraction2 / 1000 / 60 / 60) % 24), 10); // 计算剩余的小时数
  let mm = parseInt(String((duraction2 / 1000 / 60) % 60), 10); // 计算剩余的分钟数
  let ss = parseInt(String((duraction2 / 1000) % 60), 10); // 计算剩余的秒数
  let strdd = strPadLeft(dd, 2);
  let strhh = strPadLeft(hh, 2);
  let strmm = strPadLeft(mm, 2);
  let strss = strPadLeft(ss, 2);

  let str = '';
  if (dd > 0) {
    str += strdd + '天';
  }
  if (hh > 0) {
    str += strhh + '时';
  }
  str += strmm + '分';
  str += strss + '秒';
  return str;
};
// 扩展数组方法:删除指定元素
export const arrayRemove = (arr, val) => {
  let index = arr.indexOf(val);
  while (index > -1) {
    arr.splice(index, 1);
    index = arr.indexOf(val);
  }
  return arr;
};
// 将数组分割成多个二维数组 每组 len 个
export const splitArr = (arr, len = 2) => {
  let index = 0;
  let newArr = [];
  while (index < arr.length) {
    let childArr = arr.slice(index, (index += len));
    newArr.push(childArr);
  }
  return newArr;
};
// 判断是否为纯整数
export const checkInteger = value => {
  let reg = /^[1-9]\d*$|^0$/; // 注意：故意限制了 0321 这种格式，如不需要，直接reg=/^\d+$/
  return reg.test(value);
};
export const dateToGMT8StringYMD = date => {
  const GMT = getGMT_SYS();
  const GMT_SYS = GMT * 60;
  const cuZone = new Date().getTimezoneOffset();
  // 时区的差值
  let chazhiZone = 0;

  // 服务端时区和当前时区不在同一个时区
  if (GMT_SYS !== cuZone) {
    /**
     * 服务端时区和当前时区一共4种情况：
     * 1、都在西区
     * 2、都在东区
     * 3、服务端在东区、当前时区在西区
     * 4、服务端在西区、当前时区在东区
     */
    chazhiZone = shiquDiff();
    // 在服务端时区的左边的时区
    if (chazhiZone > 0) {
      const nowTime = new Date();
      // 超过一天了，得加一天
      if (nowTime.getHours() + chazhiZone / 60 >= 24) {
        const tmp1 = new Date(date).getTime() + 1000 * 60 * 60 * 24;
        const tmp2 = new Date(tmp1);
        return getYMD(tmp2);
      }
    } else if (chazhiZone < 0) {
      // 在服务端时区的右边的时区
      const nowTime = new Date();
      // 多了一天，要减去一天
      if (nowTime.getHours() + chazhiZone / 60 < 0) {
        const tmp1 = new Date(date).getTime() - 1000 * 60 * 60 * 24;
        const tmp2 = new Date(tmp1);
        return getYMD(tmp2);
      }
    }
  }
  return getYMD(date);
};
export const shiquDiff = () => {
  const GMT = getGMT_SYS();
  const GMT_SYS = GMT * 60;
  const cuZone = new Date().getTimezoneOffset();
  if (GMT_SYS === cuZone) {
    return 0;
  }
  let chazhiZone = 0;
  if (GMT_SYS >= 0 && cuZone >= 0) {
    chazhiZone = cuZone - GMT_SYS;
  } else if (GMT_SYS <= 0 && cuZone <= 0) {
    chazhiZone = -(Math.abs(cuZone) - Math.abs(GMT_SYS));
  } else if (GMT_SYS <= 0 && cuZone >= 0) {
    chazhiZone = Math.abs(cuZone) + Math.abs(GMT_SYS);
  } else if (GMT_SYS >= 0 && cuZone <= 0) {
    chazhiZone = -(Math.abs(cuZone) + Math.abs(GMT_SYS));
  }
  return chazhiZone;
};
// 获取后台返回的GMT时区 默认东八区
export const getGMT_SYS = () => {
  let { tenantInfo } = useHot().hotData;
  return tenantInfo.value.sysOptions.GMT_SYS_1; // 系统返回的时区 [小时] 默认东八区
};
// 判断是否为数字
export const checkRate = value => {
  let re = /^[0-9]+(.[0-9]{0,3})?$/;
  return re.test(value);
};
// 判断是否为数字，并且可能为1位2位小数
export const checkRate2 = value => {
  let re = /^[0-9]+(.[0-9]{0,2})?$/;
  return value !== '00' && re.test(value);
};
export const countDownHourStr = duraction => {
  let duraction2 = duraction;
  if (duraction2 <= 0) {
    duraction2 = 0;
  }
  let dd = parseInt(String(duraction2 / 1000 / 60 / 60 / 24), 10); // 计算剩余的天数
  let hh = parseInt(String((duraction2 / 1000 / 60 / 60) % 24), 10); // 计算剩余的小时数
  let mm = parseInt(String((duraction2 / 1000 / 60) % 60), 10); // 计算剩余的分钟数
  let ss = parseInt(String((duraction2 / 1000) % 60), 10); // 计算剩余的秒数
  let strhh = strPadLeft(hh, 2);
  let strmm = strPadLeft(mm, 2);
  let strss = strPadLeft(ss, 2);

  let str = '';
  if (dd > 0) {
    hh += 24 * dd;
    strhh = strPadLeft(hh, 2);
  }
  if (hh > 0) {
    str += strhh + '时';
  }
  str += strmm + '分';
  str += strss + '秒';
  return str;
};

export const getDeviceType = () => {
  let type = '';
  if (!isApp()) {
    if (isH5()) {
      type = 'MWEB';
    } else {
      type = 'PC';
    }
  } else {
    if (isIOS()) {
      type = 'IOS';
    } else {
      type = 'ANDROID';
    }
  }
  return type;
};